<template>
  <div>
    <v-card
      class="mx-auto my-2"
      elevation="12"
      style="max-width: 700px; background-color: transparent"
    >
      <div class="corpo">
        <v-container class="container container-pagina">
          <div>
            <!-- Linha superior com os botões Agendar e Meus horários -->
            <v-row>
              <v-col cols="6" xs="12" sm="6" lg="6"  xl="6" v-bind:style="divBotaoSeisColunas">
                <v-container>
                  <v-row>
                    <v-col class="d-flex justify-center">
                      <div class="card-botoes-header" v-bind:style="estiloDivIconesBotoes" @click="avancaParaTelaServicos">
                        <span v-bind:style="estiloSpanIconesBotoes">
                          <i class="fa-solid fa-calendar-days fa-3x"></i>
                        </span>
                      </div>
                    </v-col>
                  </v-row>
                  
                  <v-row>
                    <v-col class="d-flex justify-center">
                      <div v-bind:style="estiloTextoBotoes" @click="avancaParaTelaServicos">
                        <h4>Agendar</h4>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
              
              <v-col cols="6" xs="12" sm="6" lg="6"  xl="6" v-bind:style="divBotaoSeisColunas">
                <v-container>
                  <v-row>
                    <v-col class="d-flex justify-center">
                      <div class="card-botoes-header" v-bind:style="estiloDivIconesBotoes" @click="avancaParaTelaMinhaAgenda">
                        <span v-bind:style="estiloSpanIconesBotoes">
                          <i class="fa-solid fa-clock fa-3x"></i>
                        </span>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="d-flex justify-center">
                      <div v-bind:style="estiloTextoBotoes" @click="avancaParaTelaMinhaAgenda">
                        <h4>Meus horários</h4>
                      </div>
                    </v-col>
                  </v-row>  
                </v-container>
              </v-col>
            </v-row>
            
            <!-- Linha inferior com os botões Informações e Localização -->
            <v-row>
              <v-col cols="6" xs="12" sm="6" lg="6"  xl="6" v-bind:style="divBotaoSeisColunas">
                <v-container>
                  <v-row>
                    <v-col class="d-flex justify-center">
                      <div class="card-botoes-header" v-bind:style="estiloDivIconesBotoes" @click="abreInformacoes">
                        <span v-bind:style="estiloSpanIconesBotoes">
                          <i class="fa-solid fa-circle-info fa-3x"></i>
                        </span>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>  

                <v-container>
                  <v-row>
                    <v-col class="d-flex justify-center">
                      <div v-bind:style="estiloTextoBotoes" @click="abreInformacoes">
                        <h4>Informações</h4>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
              
              <v-col v-if="ativaBotaoLocalizacao" cols="6" xs="12" sm="6" lg="6"  xl="6" v-bind:style="divBotaoSeisColunas">
                <v-container>
                  <v-row>
                    <v-col class="d-flex justify-center">
                      <div class="card-botoes-header" v-bind:style="estiloDivIconesBotoes" @click="abrirMaps(dadosEstabelecimento.gps)">
                        <span v-bind:style="estiloSpanIconesBotoes">
                          <i class="fa-solid fa-map-location-dot fa-3x"></i>
                        </span>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>

                <v-container>
                  <v-row>
                    <v-col class="d-flex justify-center">
                      <div v-bind:style="estiloTextoBotoes" @click="abrirMaps(dadosEstabelecimento.gps)">
                        <h4>Localização</h4>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </div>
    </v-card>

    <!-- Modal para exibir informações do cliente -->
    <v-dialog v-model="showInfo" max-width="600">
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar :color="dadosEstabelecimento.cor_principal" v-bind:style="{color:dadosEstabelecimento.cor_texto_barra_topo_dialog_informacao}">
            {{contrato.nome}}
          </v-toolbar>
          
          <v-textarea
            auto-grow
            outlined
            rows="10"
            disabled
            class="pa-2 infocliente"
            :value="dadosEstabelecimento.informacoes_cliente"
          />
          
          <v-card-actions class="justify-end">
            <v-btn 
              small 
              v-bind:style="{backgroundColor: dadosEstabelecimento.cor_botoes, color: dadosEstabelecimento.cor_texto_botoes, textTransform: 'capitalize'}"
              @click="dialog.value = false"
            >
              Fechar
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>


<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["path"],
  data: () => {
    return {
      divBotaoSeisColunas: {},
      estiloDivIconesBotoes:{
        backgroundColor: "",
        marginTop: "",
        borderWidth: "",
        borderColor: "",
        borderStyle: "",
        borderRadius: ""
      },
      estiloSpanIconesBotoes:{
        color: ""
      },
      estiloTextoBotoes: {
        color: "", 
        cursor: "pointer", 
        width: "120px", 
      },
      showInfo: false,
      ativaBotaoLocalizacao: false
    };
  },
  async mounted() {
    this.ativaLocalizacao();
    this.setPathCliente(this.path);
    this.configurarContrato();
    this.testaF5SemNavegar();
    this.desativaComponentes();
    this.verificaLogin();
    this.setaPlanoDeFundo();
    this.setaOrigemMeusHorarios();
    this.setaOrigemLogin();
    this.bloqueiaBotaoVoltar();
  },
  computed: {
    ...mapGetters({
      dadosEstabelecimento: "getDadosEstabelecimento",
      contrato: "getContrato",
      verificaLogado: "verificaLogado"
    }),
  },
  watch: {
    dadosEstabelecimento(value) {
      this.divBotaoSeisColunas.padding = "0px";
      this.divBotaoSeisColunas.height = "200px";
      
      // value.cor_background_botoes_tela_inicial = "C6C096"; Tema de estética: cor definida para o background do botão inicial.
      this.estiloDivIconesBotoes.backgroundColor = value.cor_background_botoes_tela_inicial;
      this.estiloDivIconesBotoes.marginTop = "20px"; 
      this.estiloDivIconesBotoes.paddingTop = "20px"; 
      this.estiloDivIconesBotoes.width = "90px"; 
      this.estiloDivIconesBotoes.height = "90px"; 
      this.estiloDivIconesBotoes.borderWidth = "1px"; 
      this.estiloDivIconesBotoes.borderStyle = "solid"; 
      // this.estiloDivIconesBotoes.borderColor = "#40a22d"; Tema de estética: cor definida para a borda do botão inicial.
      this.estiloDivIconesBotoes.borderColor = value.cor_borda_botoes_tela_inicial;
      this.estiloDivIconesBotoes.borderRadius = "45px 45px 45px 45px";
      this.estiloSpanIconesBotoes.color = value.cor_icone_botoes_tela_inicial; 
      
      this.estiloTextoBotoes.color = value.cor_texto_botoes_tela_inicial;
      this.estiloTextoBotoes.cursor = "pointer";
      this.estiloTextoBotoes.width = "120px";
      this.estiloTextoBotoes.borderColor = "#40a22d"; 
      this.estiloTextoBotoes.borderColor = "#40a22d"; 
      this.estiloTextoBotoes.textAlign = "center"; 
    }
  },
  methods: {
    ...mapActions({
      ativaLocalizacao() {
        setTimeout(() => {
          console.log("gps: " + this.$store.state.contrato.gps);
          if(this.$store.state.contrato.gps !== null) {
            this.ativaBotaoLocalizacao = true;
          }
        }, 1000);
      },
      configurarContrato: "configurarContrato",
      setPathCliente: "setPathCliente",
      goToRoute: "goToRoute",
      testaF5SemNavegar: "testaF5SemNavegar",
      desativaBotoesNavegacao: "desativaBotoesNavegacao",
      bloqueiaBotaoVoltar: "bloqueiaBotaoVoltar"
    }),
    setaOrigemMeusHorarios() {
      // Variável utilizada no controle de visualização do botão retornar na PaginaMinhaAgenda
      this.$store.state.origemMeusHorarios = "paginaInicio";
    },
    setaOrigemLogin() {
      // Variável utilizada no controle de visualização do botão retornar na PaginaLogin
      this.$store.state.origemLogin = "paginaInicio";
    },
    setaPlanoDeFundo() {
      setTimeout(() => {
        if(window.location.pathname == '/home') {
          this.$store.state.visualizaLogoAgendarme = true;
          this.$store.state.visualizaTopoPaginaInicio = false;
          this.$store.state.planoFundoApp = {backgroundImage: `url("/assets/fundoCouroTesouras.png")`};
        } else {
          this.$store.state.visualizaLogoAgendarme = false;
          this.$store.state.visualizaTopoPaginaInicio = true;
          this.$store.state.planoFundoApp = {backgroundImage: `url("${this.$store.state.contrato.url_background}")`};
        }
      }, 1000);
    },
    desativaComponentes() {
      this.$store.state.controleApp.botaoHome = true;
      this.$store.state.controleApp.botaoRetornoInicio = false;
      this.$store.state.controleApp.barraNavegacao = false;
      this.$store.state.controleApp.botaoServicos = false;
      this.$store.state.controleApp.botaoProfissionais = false;
      this.$store.state.controleApp.botaoDataAgendamento = false;
      this.$store.state.controleApp.botaoResumo = false;
      
      // Quando entrar na tela inicial remove o agendamento da memória local do navegador.
      localStorage.removeItem("agendamento");
    },
    verificaLogin() {
      // Ativa ou desativa o botão do menu conforme o status do usuário logado.
      let logado = this.verificaLogado;
      this.$store.state.controleApp.botaoMenu = logado;
      this.$store.state.telaRetornoPosLogin = "";
      return logado;
    },
    avancaParaTelaServicos() {
      this.desativaBotoesNavegacao();
      this.goToRoute(`/${this.path}/servicos`);
    },
    avancaParaTelaMinhaAgenda() {
      if (this.verificaLogin()) {
        this.desativaBotoesNavegacao();
        this.goToRoute("/minha-agenda");
      } else {
        this.$store.state.telaRetornoPosLogin = "/minha-agenda";
        this.goToRoute("/login");
      }
    },
    abreInformacoes() {
      this.showInfo = true;
    },
    abrirMaps(padding) {
      window.open(padding, "_blank").focus();
    },
  },
};
</script>

<style scoped>
.corpo {
  height: 100vh;
  max-height: 580px;
}
.infocliente {
  font-size: 14px;
}
.container-pagina {
  height: 100%;
  max-width: 700px;
  margin: 0 auto;
}

.card-botoes-header {
  /* padding-top: 30px; */
  text-align: center;
  cursor: pointer;
}

.card-botoes-footer {
  text-align: center;
  cursor: pointer;
}
</style>