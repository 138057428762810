import axios from 'axios';
import store from '@/store.js'

function buildHeaders(auth) {
    let headers = null;

    if(auth) {
        headers = {
            headers: {
                'Accept': 'application/json',
                'Authorization': store.getters['getAuthorization']
            }
        };
    }
    return headers;
}

function postCall(url, dados = null, auth = false) {
    return new Promise( function(resolve, reject) {
        let headers = buildHeaders(auth);

        axios.post(url, dados, headers)
            .then( result => {resolve(result)})
            .catch( result => {
                if( result.response.status == 401 ){
                    store.dispatch("limpaLogin");
                }
                reject(result)
            })
    })
}
 
function getCall(url, auth = false) {
    return new Promise( function(resolve, reject) {
        let headers = buildHeaders(auth);
        
        axios.get(url, headers)
            .then( result => {resolve(result)})
            .catch( result => {
                if( result.response.status == 401 ){
                    store.dispatch("limpaLogin");
                }
                reject(result)
            })
    })
}

function deleteCall(url, dados) {
    return new Promise( function(resolve, reject) {
        axios.delete(url, dados)
            .then( result => {resolve(result)})
            .catch( result => {
                if( result.response.status == 401 ){
                    store.dispatch("limpaLogin");
                }
                reject(result)
            })
    })
}
export default {
    postCall,
    getCall,
    deleteCall
};