<template>
    <div>
        <v-container class="card-principal">
            <v-row>
                <v-col class="cols='12'">
                    <v-card
                        class="mx-2 my-2"
                        elevation="12"
                        v-bind:style={backgroundColor:dadosEstabelecimento.cor_background_card_formularios}
                    >
                        <v-card-title class="text-h6" v-bind:style="{backgroundColor: dadosEstabelecimento.cor_background_barra_formularios, color: dadosEstabelecimento.cor_texto_barra_formularios, padding: '10px'}">
                            <v-icon v-bind:style="{marginRight: '5px', color: dadosEstabelecimento.cor_texto_barra_formularios}"> mdi-account-edit </v-icon>
                            <span class="barra-titulo-formulario-texto">
                                Dados pessoais
                            </span>
                        </v-card-title>

                        <template>
                            <v-form ref="form" class="mx-5">
                                <v-input type="hidden" v-model="usuario.idcliente"></v-input>

                                <v-text-field
                                    label="Nome"
                                    v-model="usuario.nome"
                                    autofocus
                                    :rules="[rules.required]"
                                ></v-text-field>

                                <v-text-field
                                    type="number"
                                    label="Celular"
                                    v-mask="'(##) # ####-####'"
                                    v-model="usuario.telefone"
                                    
                                ></v-text-field>

                                <v-text-field
                                    label="Data de Nascimento"
                                    v-mask="'##/##/####'"
                                    v-model="usuario.dthr_nascimento"
                                ></v-text-field>
                            </v-form>
                        </template>

                        <v-divider class="mx-4 my-4" v-bind:style="{backgroundColor:dadosEstabelecimento.cor_barra_divisoria}"></v-divider>

                        <v-card-actions>
                            <v-btn
                                small
                                class="ma-1"
                                v-bind:style="{backgroundColor: dadosEstabelecimento.cor_botoes, color: dadosEstabelecimento.cor_texto_botoes, textTransform: 'capitalize'}"
                                @click.prevent="salvar(usuario)"
                            >
                                Salvar
                            </v-btn>

                            <v-btn 
                                small
                                v-bind:style="{backgroundColor: dadosEstabelecimento.cor_background_botoes_cancelar, color: dadosEstabelecimento.cor_texto_botoes_cancelar, textTransform: 'capitalize'}"
                                @click.prevent="goToRoute(`inicio`)"
                            >
                                Cancelar
                            </v-btn>
                        </v-card-actions>

                        <!-- Caixa de diálogo para exibição de uma mensagem informativa ao usuário. -->
                        <v-dialog
                            v-model="mostraDialogInformacao"
                            max-width="400px"
                        >
                            <v-card>
                                <v-card-title>
                                    {{mensagemDialogInformacao}}
                                </v-card-title>

                                <v-divider class="mx-4" v-bind:style="{backgroundColor:dadosEstabelecimento.cor_barra_divisoria}"></v-divider>
                                
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        small
                                        v-bind:style="{backgroundColor: dadosEstabelecimento.cor_botoes, color: dadosEstabelecimento.cor_texto_botoes, textTransform: 'capitalize'}"
                                        @click="fecharModalInformacao()"
                                    >
                                    Ok
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>


<script>
    import api from '@/api';
    import { OnRules } from 'vuetify-js-utils';
    import { mapGetters, mapActions } from "vuex";
    import moment from 'moment';

    export default {
        mixins: [OnRules],
        data: () => ({
            mostraDialogInformacao: false,
            redirecionarPara: "",
            mensagemDialogInformacao: "",
            usuario: {
                idcliente: "",
                nome: "",
                telefone: "",
                email: "",
                dthr_nascimento: ""
            }
        }),
        mounted () {
            this.ativaComponentes();
        },
        computed: {
            ...mapGetters({
                dadosEstabelecimento: "getDadosEstabelecimento"
            })
        },
        methods: {
            ...mapActions({
                goToRoute: "goToRoute"
            }),
            ativaComponentes () {
                this.$store.state.controleApp.botaoHome = true;
                this.$store.state.controleApp.barraNavegacao = false;

                // Consulta os dados do usuário para exibir na tela.
                api.buscaInformacoes()
                    .then((response) => {
                        this.usuario.idcliente = response.data.idcliente;
                        this.usuario.nome = response.data.nome;
                        this.usuario.telefone = response.data.telefone;
                        this.usuario.email = response.data.email;
                        var isodata = response.data.dthr_nascimento;
                        var data = moment(isodata, "YYYY-MM-DD");
                        this.usuario.dthr_nascimento = data.format("DD/MM/YYYY");
                    });
            },
            salvar (pUsuario) {
                if (pUsuario.nome == "") {
                    this.mensagemDialogInformacao = "Obrigatório informar: Nome!";
                    this.mostraDialogInformacao = true;
                    return false;
                }

                var data = moment(pUsuario.dthr_nascimento, "DD/MM/YYYY");
                var isodata = data.format("YYYY-MM-DD");

                // Coleta os dados alterados pelo usuário.
                let dadosAlterados = {
                    "nome": pUsuario.nome,
                    "telefone": pUsuario.telefone,
                    "dthr_nascimento": isodata,
                };

                // Altera os dados do usuário no servidor.
                api.atualizar(dadosAlterados)
                    .then((response) => {
                        console.log(response);
                        this.mensagemDialogInformacao = "Dados alterados com sucesso!";
                        this.mostraDialogInformacao = true;
                        this.redirecionarPara = `/${this.path}/inicio`;
                    });
            },
            fecharModalInformacao () {
                this.mostraDialogInformacao = false;
                if(this.redirecionarPara != ""){
                    this.goToRoute(this.redirecionarPara);
                }
            },
        }
    };
</script>

<style scoped>
    .card-principal {
        max-width: 700px; 
        margin: 0 auto;
    }
    
    .barra-titulo-formulario-texto {
        font-size: 18px;
    }
</style>