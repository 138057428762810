<template>
  <v-card
    class="mx-auto my-0 py-0"
    elevation="12"
    style="max-width: 700px; background-color: transparent"
  >
    <v-form>
      <v-container class="container-pagina" style="padding-top: 0px">
        <v-row
          justify="center"
          class="my-0 py-0"
          style="margin-top: 0px !important"
        >
          <v-col cols="7">
            <v-card-subtitle
              class="py-0 px-0"
              v-bind:style="{color: dadosEstabelecimento.cor_texto_login, fontSize: '18px'}"
            >
              Login
            </v-card-subtitle>
          </v-col>
        </v-row>
        
        <v-row justify="center" class="my-0">
          <v-col cols="7">
            <v-text-field
              label="Celular"
              dense
              solo
              v-mask="'(##) # ####-####'"
              v-model="login.telefone"
              prepend-inner-icon="mdi-phone"
            />
          </v-col>
        </v-row>

        <v-row justify="center" class="my-0">
          <v-col cols="7" class="py-0">
            <v-text-field
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              dense
              solo
              label="Senha"
              prepend-inner-icon="mdi-key-variant"
              v-model="login.senha"
            />
          </v-col>
        </v-row>

        <v-row justify="center" class="my-0">
          <v-col cols="7" class="py-0">
            <div>
              <v-btn
                v-bind:style="{backgroundColor: dadosEstabelecimento.cor_botoes, color: dadosEstabelecimento.cor_texto_botoes}"
                block
                @click.prevent="verificaAcesso(login)"
              >
                Acessar
              </v-btn>

              <v-card-subtitle>
                <span
                  class="py-0 px-0"
                  v-bind:style="{color: dadosEstabelecimento.cor_texto_esqueci_minha_senha, cursor: 'pointer'}"
                  @click="esqueciSenha(login)"
                >
                  Esqueci minha senha!
                </span>
              </v-card-subtitle>
            </div>
          </v-col>
        </v-row>

        <br />
        <v-divider class="mx-4" v-bind:style="{backgroundColor: dadosEstabelecimento.cor_barra_divisoria}"/>
        <br />

        <v-row justify="center" class="my-0">
          <v-col cols="7" class="py-0">
            <div>
              <v-btn 
                v-bind:style="{backgroundColor: dadosEstabelecimento.cor_botoes, color: dadosEstabelecimento.cor_texto_botoes}"
                block 
                @click="cadastro()"
              >
                Cadastre-se
              </v-btn>
            </div>
          </v-col>
        </v-row>
        
        <br />
        
        <v-row justify="center" class="my-0">
          <v-col cols="7" class="py-0">
            <div>
              <v-btn 
                v-bind:style="{backgroundColor: dadosEstabelecimento.cor_botoes, color: dadosEstabelecimento.cor_texto_botoes}"
                block 
                @click="voltar()"
              >
                Voltar
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-card>
</template>


<script>
import api from "@/api";
import { mapGetters, mapActions } from "vuex";

export default {
  data: () => ({
    usuarios: [],
    login: {
      email: "",
      senha: "",
      telefone: "",
    },
    agendamentoEfetuado: false,
    agendamento: {
      idagendamento: "",
      idservico: "",
      idprofissional: "",
      dataHora: "",
      idusuario: "",
    },
    textoLogin: {},
    showPassword: false,
  }),
  mounted() {
    this.ativaComponentes();
    this.ativaHome();
  },
  computed: {
    ...mapGetters({
      verificaLogado: "verificaLogado",
      dadosEstabelecimento: "getDadosEstabelecimento",
      getServico: "getServico",
      getProfissional: "getProfissional",
      getAgendamento: "getAgendamento",
      getContrato: "getContrato",
      getAuthorization: "getAuthorization",
    }),
  },
  methods: {
    ...mapActions({
      loginRealizado: "loginRealizado",
      goToRoute: "goToRoute",
      verificaAgendamentoPendente: "verificaAgendamentoPendente",
      showAlert: "showAlert",
    }),
    ativaComponentes() {
      this.$store.state.controleApp.botaoHome = true;
      this.$store.state.controleApp.barraNavegacao = false;
      this.$store.state.controleApp.lblAtivoProfissionais = false;

      this.$store.state.controleApp.botaoProfissionais = false;

      this.$store.state.controleApp.botaoDataAgendamento = false;
      this.$store.state.controleApp.botaoResumo = false;
    },
    esqueciSenha(pLogin) {
      // if (pLogin.email == "") {
      //     this.mensagemDialogInformacao = "E-mail inválido";
      //     this.mostraDialogInformacao   = true;
      //     this.mensagemValidacao        = true;
      //     return false;
      // }
      //  let objLogin = {
      //     "email": pLogin.email,
      // }
      if (pLogin.telefone == "") {
        this.showAlert("Telefone inválido!");
        return false;
      }

      let objLogin = {
        telefone: pLogin.telefone,
      };

      // Confere os dados do usuário no servidor e efetua login no sistema.
      api
        .resetPassword(objLogin)
        .then((response) => {
          console.log(response);
          this.showAlert("Você irá receber uma mensagem com sua nova senha");
        })
        .catch((error) => {
          console.log(error);
          this.showAlert(["Falha na consulta", error.response.data.message.Erro[0]]);
        });
    },
    ativaHome() {
      this.$store.state.controleApp.botaoHome = true;
    },
    verificaAcesso(pLogin) {
      this.$store.state.usuarioLogado = false;
      this.$store.state.controleApp.botaoMenu = false;

      let objLogin = {
        email: pLogin.email,
        telefone: pLogin.telefone,
        password: pLogin.senha,
      };

      // Confere os dados do usuário no servidor e efetua login no sistema.
      api
        .login(objLogin)
        .then((response) => {
          this.loginRealizado(response.data);
          setTimeout(() => {
            this.verificaAgendamentoPendente();
            this.goToRoute("/minha-agenda");
          }, 400);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.status == 401 &&
              error.response.data.error == "Unauthorized"
            ) {
              this.showAlert("Usuário não autorizado!");
            }

            if (error.response.status == 400) {
              let msgErros = "";
              let listaErros = error.response.data.message.Erro;
              for (var i = 0; i < listaErros.length; i++) {
                msgErros = listaErros[i];
                break;
              }

              if (msgErros != "") {
                this.showAlert(msgErros);
              }
            }
          } else if (error.request) {
            // A solicitação foi feita, mas nenhuma resposta foi recebida.
            console.log(
              "Requisição feita, mas nenhuma resposta recebida! (" +
                error.request +
                ")"
            );
          } else {
            // Algo aconteceu na configuração da solicitação que acionou um erro.
            console.log(
              "Erro na configuração da requisição! (" + error.message + ")"
            );
          }
        });
    },
    fechaDialogInformacao() {
      // Se foi efetuado o agendamento no servidor redireciona para a tela 'Minha agenda'.
      this.mostraDialogInformacao = false;
      if (this.agendamentoEfetuado) {
        this.agendamentoEfetuado = false;
        this.goToRoute("/minha-agenda");
      }
    },
    cadastro() {
      this.goToRoute("/cadastro-conta");
    },
    voltar() {
      if(this.$store.state.origemLogin === "home"){
        this.goToRoute("/home");
      } else {
        this.goToRoute(`inicio`);
      }
    },
  },
};
</script>

<style scoped>
.container-pagina {
  height: 100vh;
  max-height: 580px;
  max-width: 100%;
}
</style>