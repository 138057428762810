<template>
    <v-card
        class="mx-auto my-2"
        elevation="12"
        style="max-width: 700px; background-color: transparent;"
    >
        <nprogress-container />

        <v-card-title class="text-h6" v-bind:style="{backgroundColor: dadosEstabelecimento.cor_background_barra_formularios, color: dadosEstabelecimento.cor_texto_barra_formularios, padding: '10px'}">
            <v-row align-content="end">
                <v-col>
                    <v-icon v-bind:style="{marginRight: '5px', color: dadosEstabelecimento.cor_texto_barra_formularios}"> mdi-calendar-clock </v-icon>
                    <span style="font-size:16px">Meus horários</span>
                </v-col>
                <v-col style="text-align: right!important;">
                    <div v-show="ativaRetornoHome" @click="retornarParaHome()" v-bind:style="{color: dadosEstabelecimento.cor_texto_barra_formularios, cursor: 'pointer'}"> 
                        <i class="fa-solid fa-chevron-left"></i>
                        <span style="font-size:16px">Retornar</span>
                    </div>
                </v-col>
            </v-row>
        </v-card-title>

        <div class="corpo overflow-y-auto scroll-hidden">
            <v-container class="container container-pagina">
                <div v-if="carregando">
                    <v-row class="ma-6" justify="center" no-gutters>
                        <v-progress-circular :size="70" :width="7" color="blue" indeterminate />
                    </v-row>
                </div>
                <div v-else-if="!agendamentos.length">
                    <div class="pa-4 msg-nao-possui-agendamentos">
                        Você ainda não possui agendamentos
                    </div>
                    
                </div>
                <div v-else>
                    <v-row v-for="agendamento in agendamentos" :key="agendamento.idagendamento">
                        <v-col class="cols='12'">
                            <v-card v-bind:style="{backgroundColor:dadosEstabelecimento.cor_background_card_formularios}" dark>
                                <div>
                                    <v-list-item class="lista-itens-horarios">
                                        <v-list-item-avatar 
                                            flat
                                            size="30"
                                            v-bind:style="{backgroundColor: dadosEstabelecimento.cor_botoes}"
                                        >
                                            <v-icon v-bind:style="{color: dadosEstabelecimento.cor_texto_botoes}">
                                                mdi-clock
                                            </v-icon>
                                        </v-list-item-avatar>

                                        <v-list-item-content>
                                            <v-card-title 
                                                class="text-h7" 
                                                v-text="formataDataHora(agendamento.dthr_agendamento)" 
                                                v-bind:style="{color: dadosEstabelecimento.cor_texto_botoes}"
                                            />
                                        </v-list-item-content>

                                        <v-list-item-avatar 
                                            v-if="agendamento.local_resumo.gps" 
                                            flat 
                                            size="40" 
                                            v-bind:style="{backgroundColor: dadosEstabelecimento.cor_botoes}" 
                                            @click="abrirMaps(agendamento.local_resumo.gps)"
                                        >
                                            <v-icon v-bind:style="{color: dadosEstabelecimento.cor_texto_botoes}">
                                                mdi-map-search-outline
                                            </v-icon>
                                        </v-list-item-avatar>
                                    </v-list-item>

                                    <v-divider class="mx-4" v-bind:style="{backgroundColor:dadosEstabelecimento.cor_barra_divisoria}"/>
                                    
                                    <v-list-item two-line>
                                        <v-list-item-content class="lista-detalhes-horario">
                                            <v-card-subtitle 
                                                v-bind:style="{color: dadosEstabelecimento.cor_texto_botoes, padding:'5px'}"
                                                v-text="'Serviço: ' + agendamento.servico_resumo.nome">
                                            </v-card-subtitle>
                                            <v-card-subtitle 
                                                v-bind:style="{color: dadosEstabelecimento.cor_texto_botoes, padding:'5px'}"
                                                v-text="'Profissional: ' + agendamento.profissional_resumo.admin.nome">
                                            </v-card-subtitle>
                                            <v-card-subtitle 
                                                v-bind:style="{color: dadosEstabelecimento.cor_texto_botoes, padding:'5px'}"
                                                v-text="'Local: ' + agendamento.local_resumo.endereco">
                                            </v-card-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <div class="py-2 px-2">
                                        <span v-if="agendamentoPassado(agendamento.dthr_agendamento)">
                                            <v-btn block v-bind:style="{backgroundColor: '#aaaaaa'}">
                                                <span v-bind:style="{color: dadosEstabelecimento.cor_texto_botoes, textTransform: 'capitalize'}">Cancelar</span>
                                            </v-btn>
                                        </span>
                                        <span v-else>
                                            <v-btn 
                                                block 
                                                v-bind:style="{backgroundColor: dadosEstabelecimento.cor_botoes}"
                                                @click="cancelarAgendamento(agendamento.idagendamento, formataDataHora(agendamento.dthr_agendamento))"
                                            >
                                                <span v-bind:style="{color: dadosEstabelecimento.cor_texto_botoes, textTransform: 'capitalize'}">Cancelar</span>
                                            </v-btn>
                                        </span>
                                    </div>
                                </div>
                            </v-card>                                            
                        </v-col>
                    </v-row>
                </div>
            </v-container>
        </div>

        <!-- Caixa de diálogo para confirmação do cancelamento do agendamento. -->
        <v-dialog v-model="mostraDialogConfirmacao" max-width="300px">
            <v-card>
                <v-card-text v-bind:style="{color: dadosEstabelecimento.cor_texto_botoes, fontSize: '16px', padding:'12px', fontWeight: '800'}">
                    Cancelar o agengendamento:
                </v-card-text>

                <v-card-text>
                    {{mensagemDialogConfirmacao}}
                </v-card-text>

                <v-divider class="mx-4" v-bind:style="{backgroundColor:dadosEstabelecimento.cor_barra_divisoria}"></v-divider>
                
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        small
                        v-bind:style="{backgroundColor: dadosEstabelecimento.cor_background_botoes_cancelar, color: dadosEstabelecimento.cor_texto_botoes_cancelar, textTransform: 'capitalize'}"
                        @click="fechaDialogConfirmacao('N')"
                    >
                        Não
                    </v-btn>
                    <v-btn
                        small
                        v-bind:style="{backgroundColor: dadosEstabelecimento.cor_botoes, color: dadosEstabelecimento.cor_texto_botoes, textTransform: 'capitalize'}" 
                        @click="fechaDialogConfirmacao('S')"
                    >
                        Sim
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>


<script>
    import api from "@/api";
    import { mapActions, mapGetters } from "vuex";
    import NprogressContainer from 'vue-nprogress/src/NprogressContainer'
    export default {
        components: {
        NprogressContainer
    },
        data: () => ({
            idagendamento: '',
            mostraDialogConfirmacao: false,
            mensagemDialogConfirmacao: "",
            agendamentos: [],
            carregando: true,
            ativaRetornoHome: false
        }),
        mounted () {
            this.$nprogress.start();
            this.$nprogress.inc(0.2);
            this.ativaComponentes();
            // Precisa de um tempo para carregar o token do localstorage quando é F5
            this.carregando = true;
            setTimeout( ()=>{ this.getAgenda()}, 300);
        },
        computed: {
            ...mapGetters({
                getAuthorization: "getAuthorization",
                dadosEstabelecimento: "getDadosEstabelecimento",
            }),
        },
        methods: {
            ...mapActions({
                goToRoute: "goToRoute",
                desativaBotoesNavegacao: "desativaBotoesNavegacao",
            }),
            retornarParaHome() {
                this.goToRoute(`home`);
            },
            abrirMaps(padding){
                window.open(padding, '_blank').focus();
            },
            agendamentoPassado(dt_agenda) {
                const dt = new Date(dt_agenda);
                const dt_now = new Date();
                return dt < dt_now;
            },
            ativaComponentes () {
                this.$store.state.controleApp.botaoHome = true;
                this.$store.state.controleApp.botaoRetornoInicio = true;
                this.$store.state.controleApp.barraNavegacao = false;
                this.ativaRetornoHome = false;
                if(this.$store.state.origemMeusHorarios === "home") {
                    this.ativaRetornoHome = true;
                    this.$store.state.controleApp.botaoRetornoInicio = false;
                    this.dadosEstabelecimento.cor_background_barra_formularios = "#424242";
                    this.dadosEstabelecimento.cor_background_card_formularios = "#ebebeb";
                    this.dadosEstabelecimento.cor_texto_barra_formularios = "#ffffff";
                    this.dadosEstabelecimento.cor_background_botoes_cancelar = "#6c757d";
                    this.dadosEstabelecimento.cor_texto_botoes_cancelar = "#424242";
                    this.dadosEstabelecimento.cor_botoes = "#efb300";
                    this.dadosEstabelecimento.cor_texto_botoes = "#424242";
                    this.dadosEstabelecimento.cor_barra_divisoria = "8b8b8b";
                }
            },
            getAgenda () {        
                api.listarAgenda()
                .then((response) => {
                    this.carregando = false;
                    this.agendamentos = response.data;
                    this.$nprogress.done();
                })
                .catch( () => {
                    this.$nprogress.done();
                });
            },
            avancaParaTelaProfissionais () {
                this.desativaBotoesNavegacao();
                this.goToRoute("profissionais");
            },
            formataDataHora (date) {
                if (!date) return null
                const [data, hora] = date.split(' ');
                const [year, month, day] = data.split('-');
                return `${day}/${month}/${year} ${hora}`
            },
            cancelarAgendamento (idagendamento, dataHora) {
                this.idagendamento = idagendamento;
                this.mensagemDialogConfirmacao = dataHora;
                this.mostraDialogConfirmacao = true;
            },
            fechaDialogConfirmacao (resposta) {
                if( resposta == 'S' ){
                    this.$nprogress.start();
                    this.$nprogress.inc(0.2);
                    this.carregando = true;

                    // Consulta a agenda do usuário logado
                    api.deletarAgendamento(this.idagendamento)
                        .then((response) => {
                            if(response.data){
                                this.getAgenda();
                            }
                        });
                }
                this.mostraDialogConfirmacao = false;
            }
        }
    };
</script>

<style scoped>
    .corpo {
        height: 100vh;
        max-height: 580px
    }

    .container-pagina {
        height: 100%;
        max-width: 700px;
        margin: 0 auto;
    }

    .scroll-hidden::-webkit-scrollbar {
        display: none;
    }

    .msg-nao-possui-agendamentos {
        text-align: center; 
        color: white; 
        font-size: 18px;
    }

    .lista-itens-horarios {
        height: 60px; 
        padding-top: 0px; 
        padding-bottom: 0px;
    }

    .lista-detalhes-horario {
        margin: 0px; 
        padding-top: 2px; 
        padding-bottom: 2px;
    }
</style>