<template>
    <v-card
        class="mx-auto my-2 card-principal"
        elevation="12"
    >
        <div class="corpo overflow-y-auto scroll-hidden">
            <v-container class="container container-pagina">
                <v-row>
                    <v-col class="cols='12'">
                        <v-card v-bind:style="{backgroundColor:dadosEstabelecimento.cor_background_card_formularios}" dark>
                            <div>
                                <v-list class="transparent">
                                    <v-list-item :key="1">
                                        <v-list-item-title class="caption-campo">Data/Hora:</v-list-item-title>
                                        <v-list-item-subtitle class="text-left valor-campo">{{dataHora | formataDataHora}}</v-list-item-subtitle>
                                    </v-list-item>
                                    <v-list-item :key="2">
                                        <v-list-item-title class="caption-campo">Serviço:</v-list-item-title>
                                        <v-list-item-subtitle class="text-left valor-campo">{{servico}}</v-list-item-subtitle>
                                    </v-list-item>
                                    <v-list-item :key="3">
                                        <v-list-item-title class="caption-campo">Profissional:</v-list-item-title>
                                        <v-list-item-subtitle class="text-left valor-campo">{{profissional}}</v-list-item-subtitle>
                                    </v-list-item>
                                    <v-list-item :key="4">
                                        <v-list-item-title class="caption-campo">Valor:</v-list-item-title>
                                        <v-list-item-subtitle class="text-left valor-campo">{{valor}}</v-list-item-subtitle>
                                    </v-list-item>
                                </v-list>

                                <v-card-title v-bind:style="{color:dadosEstabelecimento.cor_texto_botoes, fontWeight: 'bold', paddingTop: '2px', paddingBottom: '2px'}">
                                    Confirma agendamento?
                                </v-card-title>
                                
                                <v-divider class="mx-4"  v-bind:style="{backgroundColor:dadosEstabelecimento.cor_barra_divisoria}"></v-divider>

                                <v-card-actions>
                                    <v-btn 
                                        class="mt-5" 
                                        small 
                                        v-bind:style="{backgroundColor: dadosEstabelecimento.cor_botoes, color: dadosEstabelecimento.cor_texto_botoes, textTransform: 'capitalize'}" 
                                        @click="confirmaAgendamento"
                                    >
                                        Sim
                                    </v-btn>
                                    
                                    <v-btn 
                                        class="mt-5"
                                        small
                                        v-bind:style="{backgroundColor: dadosEstabelecimento.cor_background_botoes_cancelar, color: dadosEstabelecimento.cor_texto_botoes_cancelar, textTransform: 'capitalize'}"
                                        @click="cancelaAgendamento"
                                    >
                                        Não
                                    </v-btn>
                                </v-card-actions>
                            </div>
                        </v-card>                                            
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </v-card>
</template>

<script>
    import api from '@/api';
    import { mapActions, mapGetters } from "vuex";
    
    export default {
        props: ['path'],
        data: () => ({
            servico: "",
            profissional: "",
            dataHora: "",
            valor: ""
        }),
        async mounted () {
            this.testaF5();
            this.bloqueiaBotaoVoltar();
            this.ativaComponentes();
            this.getResumo();
        },
        computed: {
            ...mapGetters({
                verificaLogado: "verificaLogado",
                dadosEstabelecimento: "getDadosEstabelecimento",
                getServico: "getServico",
                getProfissional: 'getProfissional',
                getAgendamento: "getAgendamento",
                getContrato: "getContrato",
                getAgendamentoPendente: "getAgendamentoPendente"
            })
        },

        methods: {
            ...mapActions({
                testaF5: "testaF5",
                bloqueiaBotaoVoltar: "bloqueiaBotaoVoltar",
                goToRoute: "goToRoute",
                setAgendamentoPendente: "setAgendamentoPendente",
                showAlert: "showAlert",
                salvarServicoSelecionado: "salvarServicoSelecionado",
                salvarProfissionalSelecionado: "salvarProfissionalSelecionado",
                salvarAgendamentoSelecionado: "salvarAgendamentoSelecionado",
                verificaAgendamentoPendente: "verificaAgendamentoPendente"

            }),
            ativaComponentes () {
                this.$store.state.controleApp.botaoHome = true;
                this.$store.state.controleApp.botaoRetornoInicio = true;
                this.$store.state.controleApp.barraNavegacao = true;
                this.$store.state.controleApp.lblAtivoResumo = true;

                if(this.$store.state.controleApp.botaoResumo == false) {
                    this.$store.state.controleApp.botaoResumo = true;
                }
            },
            getResumo () {
                this.valor = this.getServico.custo;
                this.dataHora = `${this.getAgendamento.data} ${this.getAgendamento.hora}`;
                this.servico = this.getServico.nome;
                this.profissional = this.getProfissional.admin.nome;
            },
            confirmaAgendamento () {
                this.setAgendamentoPendente(true);
                // Verifica se o usuário esta logado.
                if (this.verificaLogado) {
                    // Se o usuário esta logado coleta o token do login.
                    api.buscaInformacoes()
                    .then(() => {
                        setTimeout( () => { 
                            this.verificaAgendamentoPendente();
                            this.goToRoute("/minha-agenda") 
                        }, 500);
                    })
                    .catch( (response) => {
                        this.showAlert("Falha ao buscar informações!");
                        console.log(response);
                    });
                } else {
                    // O usuário não esta logado.
                    // Redireciona para a tela de login.
                    // this.$store.state.telaRetornoPosLogin = "/inicio";
                    this.goToRoute("/login");
                }
            },
            cancelaAgendamento () {
                this.setAgendamentoPendente(false);
                this.salvarServicoSelecionado("");
                this.salvarProfissionalSelecionado("");
                this.salvarAgendamentoSelecionado("");

                this.$store.state.controleApp.botaoHome = false;
                this.$store.state.controleApp.barraNavegacao = false;
                this.$store.state.controleApp.lblAtivoServicos = false;
                this.$store.state.controleApp.lblAtivoProfissionais = false;
                this.$store.state.controleApp.lblAtivoDataAgendamento = false;
                this.$store.state.controleApp.lblAtivoResumo = false;
                this.goToRoute(`/${this.path}/inicio`);
            }
        }
    };
</script>

<style scoped>
    .corpo {
        height: 100vh;
        max-height: 580px
    }

    .container-pagina {
        height: 100%;
        max-width: 700px;
        margin: 0 auto;
    }

    .scroll-hidden::-webkit-scrollbar {
        display: none;
    }

    .caption-campo {
        max-width: 100px; 
        color: black; 
        font-weight: bold;
    }

    .valor-campo {
        color: #424242 !important; 
        font-weight: bold;
    }

    .card-principal {
        max-width: 700px; 
        background-color: transparent;
    }
</style>