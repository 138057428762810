<template>
    <v-card
        class="mx-auto my-2"
        elevation="12"
        style="max-width: 700px; 
        background-color: transparent;"
    >
        <v-card-title class="text-h6" v-bind:style="{backgroundColor: dadosEstabelecimento.cor_background_barra_formularios, color: dadosEstabelecimento.cor_texto_barra_formularios, padding: '10px'}">
            <v-icon v-bind:style="{marginRight: '5px', color: dadosEstabelecimento.cor_texto_barra_formularios}">mdi-content-cut</v-icon>
            Serviços
        </v-card-title>

        <!-- Campo pesquisar -->
        <v-col :cols="12" v-bind:style={backgroundColor:dadosEstabelecimento.cor_background_campo_pesquisa_servicos} dark>
            <v-text-field v-model="filterText" clearable outlined dense hide-details="true" label="Pesquisar" append-outer-icon="mdi-magnify" />
        </v-col>
          
        <div class="corpo overflow-y-auto scroll-hidden">
            <v-container class="container container-pagina">
                <v-card v-bind:style={backgroundColor:dadosEstabelecimento.cor_background_card_formularios} dark>
                    <div>
                        <v-list v-bind:style={backgroundColor:dadosEstabelecimento.cor_background_card_formularios}>
                            <v-list-group v-for="categoriaServico in listaCategoriaServicos" :key="categoriaServico.servico" :value="false">
                                <template v-slot:activator>
                                    <v-list-item-title v-bind:style="{color: dadosEstabelecimento.cor_texto_formularios, fontWeight: 'bolder'}">
                                        {{ categoriaServico[0].categoria.descricao }}
                                    </v-list-item-title>
                                </template>

                                <span v-for="(servico, i) in categoriaServico" :key="i" >
                                    <v-list-item link class="px-0 py-2">
                                        <v-col cols="12">
                                            <v-card color="#ebebeb" dark>
                                                <v-row>
                                                    <v-col cols="12" xl="8" md="12" class="py-1 px-8">
                                                        <span class="text-h6"  v-bind:style="{color: dadosEstabelecimento.cor_texto_formularios}"> {{servico.nome}}</span>
                                                        <v-divider class="mx-0" v-bind:style="{backgroundColor:dadosEstabelecimento.cor_barra_divisoria}"/>
                                                        <span v-bind:style="{color: dadosEstabelecimento.cor_texto_formularios}">{{servico.descricao}}</span>
                                                    </v-col>

                                                    <v-col cols="12" xl="4" md="12" class="py-0 px-12">
                                                        <span class="text-h6"></span>
                                                        <span v-bind:style="{color: dadosEstabelecimento.cor_texto_formularios, fontWeight: 'bolder'}">
                                                            {{servico.custo}}
                                                        </span>
                                                    </v-col>

                                                    <v-col cols="12" xl="4" md="12" class="py-0 px-6">
                                                        <v-card-actions>
                                                            <v-btn 
                                                                block
                                                                small 
                                                                class="mt-5"
                                                                v-bind:style="{backgroundColor: dadosEstabelecimento.cor_botoes}" 
                                                                @click="avancaParaTelaProfissionais(servico)"
                                                            >
                                                                <span v-bind:style="{color: dadosEstabelecimento.cor_texto_botoes, textTransform: 'capitalize'}">
                                                                    Selecionar
                                                                </span>
                                                            </v-btn>
                                                        </v-card-actions>
                                                    </v-col>
                                                </v-row>
                                            </v-card>                                            
                                        </v-col>
                                    </v-list-item>
                                </span>
                            </v-list-group>
                        </v-list>
                    </div>
                </v-card>
            </v-container>
        </div>
    </v-card>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
    import api from "@/api";

    export default {
        props: ['path'],
        data: () => ({
            admins: [
                ['Management', 'mdi-account-multiple-outline'],
                ['Settings', 'mdi-cog-outline'],
            ],
            categoriasServico: {},
            servicos: [],
            filterText: ""
        }),
        async mounted () {
            this.ativaComponentes();
            this.getServicos();
            this.testaF5();
            this.bloqueiaBotaoVoltar();
        },
        computed: {
            ...mapGetters({
                dadosEstabelecimento: "getDadosEstabelecimento"
            }),
            listaCategoriaServicos() {
                let retorno = {};
                for (var [key, value] of Object.entries(this.categoriasServico)) {
                    for (var [subKey, subValue] of Object.entries(value)) {
                        if(
                            subValue.descricao.toUpperCase().includes(this.filterText.toUpperCase()) ||
                            subValue.nome.toUpperCase().includes(this.filterText.toUpperCase())  
                        ){
                            if(retorno[subValue.categoria.descricao]) {
                                retorno[subValue.categoria.descricao].push(subValue);
                            } else {
                                retorno[subValue.categoria.descricao] = [subValue];
                            }
                        }
                    }
                }
                return retorno;
            },
        },
        methods: {
            /*eslint no-unused-vars: "warn"*/
            ...mapActions({
                goToRoute: "goToRoute",
                testaF5: "testaF5",
                bloqueiaBotaoVoltar: "bloqueiaBotaoVoltar",
                desativaBotoesNavegacao: "desativaBotoesNavegacao",
                salvarServicoSelecionado: "salvarServicoSelecionado"
            }),
            getServicos () {
                api.buscaServicos(this.path)
                .then((response) => {
                    this.servicos = response.data;
                    
                    // Busca todas as categorias
                    this.categoriasServico = {};
                    this.servicos.forEach(element => {
                        this.categoriasServico[element.categoria_idcategoria] = [];
                    });

                    // Insere os serviços em cada item
                    this.servicos.forEach(element => {
                        this.categoriasServico[element.categoria_idcategoria].push(element);
                    });
                });
            },
            ativaComponentes () {
                this.$store.state.controleApp.botaoHome = true;
                this.$store.state.controleApp.botaoRetornoInicio = true;
                this.$store.state.controleApp.barraNavegacao = true;
                this.$store.state.controleApp.lblAtivoServicos = true;
                
                if(this.$store.state.controleApp.botaoServicos == false) {
                    this.$store.state.controleApp.botaoServicos = true;
                }

                this.$store.state.controleApp.botaoProfissionais = false;
                this.$store.state.controleApp.botaoDataAgendamento = false;
                this.$store.state.controleApp.botaoResumo = false;
            },
            avancaParaTelaProfissionais (pServico) {
                this.salvarServicoSelecionado([pServico]);
                this.desativaBotoesNavegacao();
                this.goToRoute(`profissionais`);
            }
        },
    };
</script>

<style scoped>
    .corpo {
        height: 100vh;
        max-height: 580px
    }

    .container-pagina {
        height: 100%;
        max-width: 700px;
        margin: 0 auto;
    }

    .scroll-hidden::-webkit-scrollbar {
        display: none;
    }
</style>