<template>
  <v-app>
    <v-main class="main-class" v-bind:style=cssFundoAplicacao>
      <!-- Barra de progresso com temporizador -->
      <div
        v-if="carregandoPagina"
        style="position: absolute; height: 100vh; width: 100vw; background:#060606b8; z-index: 100;"
        class="d-flex justify-center align-center mb-6"
      >
        <div class="pa-2">
          <v-progress-circular
            :size="70"
            :width="7"
            color="#3535ff"
            indeterminate
          />
        </div>
      </div>

      <!-- Template contendo configuração do topo da página para o agendarme -->
      <template v-if="cotrolaVisualizacaoLogoAgendarme">
        <v-container class="container">
          <v-row>
            <v-col cols="12" style="background-color: #242730;">
              <v-img width="170" height="160" alt="Logo do estabelecimento" class="imagem-logo-barbearia" src="/assets/android-chrome-512x512.png"/>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <!-- Template contendo configuração do topo da página para o cliente -->
      <template v-if="cotrolaVisualizacaoTopoPaginaInicio">
        <v-container class="container">
          <v-row v-if="getControleApp.barraTopo">
            <v-col cols="12" class="py-0 px-0">
              <!-- Barra do topo -->
              <v-toolbar :color="dadosEstabelecimento.cor_principal" dark dense width=100%>
                <!-- Menu -->
                <v-menu v-if="getControleApp.botaoMenu">
                  <!-- Botão menu -->
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn fab x-small v-bind="attrs" v-on="on" :color="dadosEstabelecimento.cor_botao_menu" class="mr-4">
                      <v-icon :color="dadosEstabelecimento.cor_principal" style="filter:brightness(20%);">
                        mdi-menu
                      </v-icon>
                    </v-btn>
                  </template>

                  <!-- Itens do menu -->
                  <v-list v-bind:style="menuLista">
                    <v-list-item @click="editarDadosPessoais" v-bind:style="menuListaItem">
                      <v-icon small class="mx-2" :color="dadosEstabelecimento.cor_principal">mdi-account</v-icon>
                      <v-list-item-title class="menu-lista-item-texto">Dados pessoais</v-list-item-title>
                    </v-list-item>

                    <v-divider class="mx-1" color="black"/>

                    <v-list-item @click="sairDoSitema" v-bind:style="menuListaItem">
                      <v-icon small class="mx-2" :color="dadosEstabelecimento.cor_principal">mdi-exit-to-app</v-icon>
                      <v-list-item-title class="menu-lista-item-texto">Sair</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <!-- Botão Home -->
                <v-btn  v-if="getControleApp.botaoHome" fab x-small :color="dadosEstabelecimento.cor_botao_home" @click="retornaParaTelaInicial">
                  <v-icon style="filter: brightness(20%);">mdi-home</v-icon>
                </v-btn>

                <!-- Botão retorna para o início -->
                <v-btn  v-if="getControleApp.botaoRetornoInicio" v-bind:style="{color: dadosEstabelecimento.cor_botao_home, marginLeft:'15px'}" fab x-small @click.prevent="goToRoute(`inicio`)">
                  <i class="fa-solid fa-circle-chevron-left fa-3x"></i>
                </v-btn>

                <v-spacer />

                <!-- Botão Alertas -->
                <v-btn :color="dadosEstabelecimento.cor_botao_alerta" icon v-if="getControleApp.botaoSinoAlarmes">
                  <v-icon>mdi-bell</v-icon>
                </v-btn>
              </v-toolbar>
            </v-col>
          </v-row>

          <!-- Logo do estabelecimento -->
          <v-row v-if="dadosEstabelecimento  && dadosEstabelecimento.url_logo">
            <v-col cols="12" class="fundo-imagem-logo-barbearia">
              <v-img width="170" height="160" alt="Logo do estabelecimento" class="imagem-logo-barbearia" :src="dadosEstabelecimento.url_logo" />
            </v-col>
          </v-row>

          <!-- Barra de emdereço e barra de navegação -->
          <v-row style="clear: both; line-height: 50px; color: black" v-bind:style="{backgroundColor: dadosEstabelecimento.cor_secundaria, color: dadosEstabelecimento.cor_texto_barra_endereco}" >
            <v-col cols="12" class="texto-rodape-cabecalho py-0 px-1 pt-5">
              <h5>
                {{ dadosEstabelecimento.enderecoCompleto }}
              </h5>
            </v-col>
            <v-col cols="12" class="texto-rodape-cabecalho py-0 px-0 pt-5">
              <template v-if="getControleApp.barraNavegacao">
                <div class="text-center" v-bind:style="barraNavegacao">
                  <v-row dense no-gutters justify="center">
                    <!-- Botão para acionar a página Serviços -->
                    <v-col cols="12" sm="3" md="3"
                      @click="avancaPagina('servicos')" width="100%"
                    >
                      <v-btn v-show="getControleApp.botaoServicos" block outlined class="pa-2"  v-bind:style="botoesNavegacaoPaginas">
                        <v-icon small v-bind:style="{color:dadosEstabelecimento.cor_texto_botoes_barra_navegacao}">
                          mdi-content-cut
                        </v-icon>

                        <span v-bind:style="{color:dadosEstabelecimento.cor_texto_botoes_barra_navegacao, textTransform: 'capitalize'}">
                          Serviços
                        </span>
                      </v-btn>
                    </v-col>

                    <!-- Botão para acionar a página Profissionais -->
                    <v-col cols="12" sm="3" md="3">
                      <v-btn v-show="getControleApp.botaoProfissionais" block outlined class="pa-2" v-bind:style="botoesNavegacaoPaginas" @click="avancaPagina('profissionais')" width="100%">
                        <v-icon small v-bind:style="{color:dadosEstabelecimento.cor_texto_botoes_barra_navegacao}">
                          mdi-account
                        </v-icon>

                        <span v-bind:style="{color:dadosEstabelecimento.cor_texto_botoes_barra_navegacao, textTransform: 'capitalize'}">
                          Profissionais
                        </span>
                      </v-btn>
                    </v-col>
                    
                    <!-- Botão para acionar a página Data/Hora -->
                    <v-col cols="12" sm="3" md="3">
                      <v-btn v-show="getControleApp.botaoDataAgendamento" block outlined class="pa-2" v-bind:style="botoesNavegacaoPaginas" @click="avancaPagina('data-agendamento')" width="100%">
                        <v-icon small v-bind:style="{color:dadosEstabelecimento.cor_texto_botoes_barra_navegacao}">
                          mdi-calendar-clock
                        </v-icon>

                        <span v-bind:style="{color:dadosEstabelecimento.cor_texto_botoes_barra_navegacao, textTransform: 'capitalize'}">
                          Data/Hora
                        </span>
                      </v-btn>
                    </v-col>

                    <!-- Botão para acionar a página Resumo -->
                    <v-col cols="12" sm="3" md="3">
                      <v-btn block outlined v-show="getControleApp.botaoResumo" class="pa-2" v-bind:style="botoesNavegacaoPaginas" @click="avancaPagina('resumo')" width="100%">
                        <v-icon small v-bind:style="{color:dadosEstabelecimento.cor_texto_botoes_barra_navegacao}">
                          mdi-playlist-check
                        </v-icon>

                        <span v-bind:style="{color:dadosEstabelecimento.cor_texto_botoes_barra_navegacao, textTransform: 'capitalize'}">
                          Resumo
                        </span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </template>
            </v-col>
          </v-row>

          <!-- Caixa de diálogo para exibição de uma mensagem informativa ao usuário. -->
          <v-dialog v-model="mostraDialogInformacao" max-width="348px">
            <v-card>
              <v-card-title>
                <div v-if="!Array.isArray(mensagemDialogInformacao)">
                  {{ mensagemDialogInformacao }}
                </div>
                <div v-else>
                  <div v-for="(msg,key) in mensagemDialogInformacao" :key="key">
                    <div v-if="!key">
                      {{msg}}
                    </div>
                    <div v-else style="font-size: 12px;">
                      {{msg}}
                    </div>
                  </div>
                </div>
              </v-card-title>

              <v-divider class="mx-4" v-bind:style="{backgroundColor:dadosEstabelecimento.cor_barra_divisoria}"/>
                  
              <v-card-actions>
                <v-spacer />
                <v-btn 
                  small 
                  v-bind:style="{backgroundColor: dadosEstabelecimento.cor_botoes, color: dadosEstabelecimento.cor_texto_botoes, textTransform: 'capitalize'}"
                  @click="fechaDialogInformacao"
                >
                  Ok
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
      </template>

      <!-- ROUTER -->
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
  import api from "@/api";
  import { mapGetters, mapActions } from "vuex";

  export default {
    name: 'App',
    data: () => {
      return {
        mostraDialogInformacao: false,
        mensagemDialogInformacao: "",
        intervalId: null,
        menuLista: {},
        menuListaItem: {},
        botoesNavegacaoPaginas: {},
        barraNavegacao: {},
        carregando: true,
        attrs: {
          class: 'mb-6',
          boilerplate: true,
          elevation: 2,
        }
      }
    },
    async mounted () {
      this.configurarContrato();
      this.carregarStore();
      this.renovaToken();
      this.setaPlanoDeFundo();
    },
    computed: {
      ...mapGetters(
        {
          carregandoPagina: "getCarregando",
          dadosEstabelecimento: "getDadosEstabelecimento",
          verificaLogado: "verificaLogado",
          getControleApp: "getControleApp",
          syncAlert: "syncAlert",

        }
      ),
      cssFundoAplicacao() {
        return this.$store.state.planoFundoApp;
      },
      cotrolaVisualizacaoLogoAgendarme() {
        return this.$store.state.visualizaLogoAgendarme;
      },
      cotrolaVisualizacaoTopoPaginaInicio() {
        return this.$store.state.visualizaTopoPaginaInicio;
      }
    },
    watch: {
      dadosEstabelecimento(value) {
        this.barraNavegacao.backgroundColor = value.cor_fundo_barra_navegacao;
        this.barraNavegacao.padding = "4px";

        this.botoesNavegacaoPaginas.backgroundColor = value.cor_botoes_barra_navegacao;
        // this.botoesNavegacaoPaginas.borderColor = value.cor_botoes;
        
        this.menuLista.backgroundColor = value.cor_menu;
        this.menuLista.padding = "1px";
        this.menuLista.cursor = "pointer";
        
        this.menuListaItem.backgroundColor = value.cor_menu;
      },
      syncAlert: {
        deep:true,
        handler: function(val) {
          this.mostraDialogInformacao = val.show;
          this.mensagemDialogInformacao = val.text;
          this.timeout = val.timeout;
        }
      }
    },
    methods: {
      ...mapActions(
        {
          configurarContrato: "configurarContrato",
          carregarStore: "carregarStore",
          limpaLogin: "limpaLogin",
          goToRoute: "goToRoute",
          desativaBotoesNavegacao: "desativaBotoesNavegacao",
          loginRealizado: "loginRealizado",
        }
      ),
      setaPlanoDeFundo() {
        setTimeout(() => {
          if(window.location.pathname == '/home') {
            this.$store.state.visualizaLogoAgendarme = true;
            this.$store.state.visualizaTopoPaginaInicio = false;
            this.$store.state.planoFundoApp = {backgroundImage: `url("/assets/fundoCouroTesouras.png")`};
          } else {
            this.$store.state.visualizaLogoAgendarme = false;
            this.$store.state.visualizaTopoPaginaInicio = true;
            this.$store.state.planoFundoApp = {backgroundImage: `url("${this.dadosEstabelecimento.url_background}")`};
          }
        }, 1000);
      },
      renovaToken() {
        if(this.verificaLogado){
          clearTimeout(this.intervalId);
          this.intervalId = setTimeout( () => {
            api.refresh()
          .then((response) => {
            this.loginRealizado(response.data);
          })
          .catch( () =>{
            this.limpaLogin();
          });
          }, 45000);
        }
      },
      retornaParaTelaInicial () {
        this.$store.state.controleApp.BotaoHome = false;
        this.$store.state.controleApp.BarraNavegacao = false;
        this.$store.state.controleApp.lblAtivoDataAgendamento = false;
        this.$store.state.controleApp.lblAtivoResumo = false;
        this.goToRoute("/home");
      },
      avancaPagina (pPagina) {
        this.desativaBotoesNavegacao();

        switch (pPagina) {
          case 'servicos':
            this.$store.state.controleApp.lblAtivoServicos = true;
            break;
          case 'profissionais':
            this.$store.state.controleApp.lblAtivoProfissionais = true;
            break;
          case 'data-agendamento':
            this.$store.state.controleApp.lblAtivoDataAgendamento = true;
            break;
          case 'resumo':
            this.$store.state.controleApp.lblAtivoResumo = true;
            break;
        }
        this.goToRoute(pPagina);
      },
      editarDadosPessoais () {
        this.goToRoute("/dados-pessoais");  
      },
      sairDoSitema () {
        api.logout()
        .then((response) => {
          this.limpaLogin();
          this.goToRoute("/inicio");
          this.mensagemDialogInformacao = response.data[0];
          this.mostraDialogInformacao = true;
        });
      },
      fechaDialogInformacao () {
        this.mostraDialogInformacao = false;
      }
    }
  };
</script>

<style scoped>
  .main-class {
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center;
    background-attachment:fixed;
  }
  
  .container {
    max-width: 700px; 
    margin: 0 auto;
  }

  .texto-rodape-cabecalho {
    display: inline-block;
    vertical-align: middle;
    line-height: 1.5; 
    text-align: center;
    font-size: 20px;
  }

  .texto-botao-ok {
    text-transform: capitalize;
    color:#2f2300;
  }

  .menu-lista-item-texto {
    font-size: 14px;
  }

  .fundo-imagem-logo-barbearia {
    background-color: white;
  }
  
  .imagem-logo-barbearia {
    display: block; 
    margin-left: auto;
    margin-right: auto;
  }
</style>
