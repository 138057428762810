<template>
    <div>
        <v-container class="card-principal">
            <v-row>
                <v-col class="cols='12'">
                    <v-card
                        class="mx-2 my-2"
                        elevation="12"
                        color="#ebebeb"
                    >
                        <v-card-title class="text-h6" v-bind:style="barraTituloFormulario">
                            <v-icon class="barra-titulo-formulario-icone">
                                mdi-account-edit
                            </v-icon>
                            <span class="barra-titulo-formulario-texto">
                                Recuperar senha
                            </span>
                        </v-card-title>

                        <template>
                            <v-form ref="form" class="mx-5">

                                <v-text-field
                                    label="token"
                                    :disabled="hasToken"
                                    v-model="token"
                                ></v-text-field>
                                
                                <v-text-field
                                    label="Senha"
                                    v-model="usuario.senha"
                                    type="password"
                                    :rules="[rules.required]"
                                ></v-text-field>

                                <v-text-field
                                    label="Repita a senha"
                                    v-model="usuario.repitaSenha"
                                    type="password"
                                    :rules="[rules.required]"
                                ></v-text-field>
                            </v-form>
                        </template>

                        <v-divider class="mx-4 my-4" color="#efb300"></v-divider>

                        <v-card-actions>
                            <v-btn
                                small
                                class="mr-1 botoes-formulario-texto"
                                color="#efb300"
                                @click.prevent="salvar(usuario)"
                            >
                                Salvar
                            </v-btn>

                            <v-btn 
                                small
                                color="#6c757d"
                                class="botoes-formulario-texto"
                                @click.prevent="voltar()"
                            >
                                Voltar
                            </v-btn>
                        </v-card-actions>

                        <!-- Caixa de diálogo para exibição de uma mensagem informativa ao usuário. -->
                        <v-dialog
                            v-model="mostraDialogInformacao"
                            max-width="560px"
                        >
                            <v-card>
                                <v-card-title>
                                    {{mensagemDialogInformacao}}
                                </v-card-title>

                                <v-divider class="mx-4" color="#efb300"></v-divider>
                                
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        small
                                        color="#efb300"
                                        class="texto-botao-ok"
                                        @click="fecharModalInformacao()"
                                    >
                                    Ok
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>


<script>
    import api from "@/api";
    import { OnRules } from 'vuetify-js-utils';
    import { mapActions, mapGetters } from "vuex";

    export default {
        mixins: [OnRules],
        props: ['token'],
        data: () => ({
            mostraDialogInformacao: false,
            redirecionarPara: "",
            mensagemDialogInformacao: "",
            usuario: {
                nome: "",
                telefone: "",
                email: "",
                dataNascimento: "",
                senha: "",
                repitaSenha: ""
            },
            usuarios: [],
            barraTituloFormulario : {
                'background-color': '#424242',
                'color': 'white',
                'padding': '10px'
            },
            agendamentoEfetuado: false,
            agendamento : {
                idagendamento: "",
                idservico: "",
                idprofissional: "",
                dataHora: "",
                idusuario: ""
            },
            mensagemValidacao: false,
        }),
        mounted () {
            this.ativaComponentes();
            this.getDadosBarbearia();
        },
        computed: {
            hasToken() {
                return !!this.token;
            },
            ...mapGetters({
                getAgendamentoPendente: "getAgendamentoPendente",
            })
        },

        methods: {
            ...mapActions({
                goToRoute: "goToRoute",
                setAgendamentoPendente: "setAgendamentoPendente",
                // verificaAgendamentoPendente: "verificaAgendamentoPendente"
            }),
            ativaComponentes () {
                this.$store.state.controleApp.botaoHome      = true;
                this.$store.state.controleApp.barraNavegacao = false;
            },
            getDadosBarbearia () {
                if (localStorage.getItem("contrato") != null) {
                    let contrato     = JSON.parse(localStorage.getItem("contrato"));

                    if( contrato.cor_principal == null ){
                        this.barraTituloFormulario['background-color'] = '#424242';
                    } else {
                        this.barraTituloFormulario['background-color'] = contrato.cor_principal;
                    }
                }
            },
            salvar(pUsuario) {

                if (this.token == "") {
                    this.mensagemDialogInformacao = "Token inválido";
                    this.mostraDialogInformacao   = true;
                    this.mensagemValidacao        = true;
                    return false;
                }

                if (pUsuario.senha == "") {
                    this.mensagemDialogInformacao = "Obrigatório informar: Senha!";
                    this.mostraDialogInformacao   = true;
                    this.mensagemValidacao        = true;
                    return false;
                }

                if (pUsuario.repitaSenha == "") {
                    this.mensagemDialogInformacao = "Obrigatório informar: Repita senha!";
                    this.mostraDialogInformacao   = true;
                    this.mensagemValidacao        = true;
                    return false;
                }

                if (pUsuario.senha !== pUsuario.repitaSenha) {
                    this.mensagemDialogInformacao = "A senha informada não é igual a senha repetida!";
                    this.mostraDialogInformacao   = true;
                    this.mensagemValidacao        = true;
                    return false;
                }

                // Coleta os dados do novo usuário.
                let usuarios = {
                    "code": this.token, 
                    "password": pUsuario.senha,
                };

                api.setPassword(usuarios)
                    .then( (response) => {
                        // Coleta o token do cadsatro do usuário no sistema.
                        this.$store.state.usuarioToken   = response.data.access_token;
                        this.$store.state.usuarioLogado  = true;
                        this.$store.state.controleApp.botaoMenu = true;

                        this.mensagemDialogInformacao = "Cadastro realizado!";
                        this.agendamentoEfetuado    = false;
                        this.mostraDialogInformacao = true;

                        setTimeout( () => { 
                            // this.verificaAgendamentoPendente();
                            this.goToRoute("/minha-agenda") 
                        }, 500);
                    }
                )
                .catch( (error) => {
                    if (error.response) {
                        let msgErros   = "";
                        let listaErros = error.response.data.message.Erro;
                        for(var i = 0; i < listaErros.length; i++) {
                            msgErros = listaErros[i];
                            break;
                        }

                        if( msgErros != "" ) {
                            this.mensagemDialogInformacao = msgErros;
                            this.mensagemValidacao        = true;
                            this.mostraDialogInformacao   = true;
                        }
                    } else if (error.request) {
                        // A solicitação foi feita, mas nenhuma resposta foi recebida.
                        console.log("Requisição feita, mas nenhuma resposta recebida! (" + error.request + ")");
                    } else {
                        // Algo aconteceu na configuração da solicitação que acionou um erro.
                        console.log("Erro na configuração da requisição! (" + error.message + ")");
                    }
                });
            },
            voltar () {
                this.goToRoute("/login");    
            },
            fecharModalInformacao () {
                this.mostraDialogInformacao = false;
                console.log("mensagemValidacao: " + this.mensagemValidacao);
                // Se não for uma mensagem de validação exibe a mensagem e redireciona para uma tela.
                if ( !this.mensagemValidacao ) {
                    if ( this.agendamentoEfetuado ) {
                        // Se foi efetuado o agendamento no servidor redireciona para a tela 'Meus horários'.
                        this.agendamentoEfetuado = false;
                        this.goToRoute("/minha-agenda");
                    } else {
                        // Se não foi efetuado o agendamento no servidor redireciona para a tela 'Início'.
                        this.goToRoute(`${this.path}/inicio`);
                    }
                } else {
                    // Se for uma mensagem de validação exibe a mensagem e pemanece na tela atual.
                    this.mensagemValidacao = false;
                }
            }
        }
    };
</script>

<style scoped>
    .corpo {
        height: 100vh;
        max-height: 580px
    }

    .container-pagina {
        height: 100%;
        max-width: 700px;
        margin: 0 auto;
    }

    .scroll-hidden::-webkit-scrollbar {
        display: none;
    }

    .msg-confirmacao {
        color: black;
        font-weight: bold;
        padding-top: 2px;
        padding-bottom: 2px;
    }
    
    .caption-campo {
        max-width: 100px; 
        color: black; 
        font-weight: bold;
    }

    .valor-campo {
        color: #424242 !important; 
        font-weight: bold;
    }

    .texto-botao {
        text-transform: capitalize;
        color:#2f2300;
    }

    .card-principal {
        max-width: 700px; 
        margin: 0 auto;
    }
    
    .barra-titulo-formulario-icone {
        margin-right:5px; 
        color: white;
    }

    .barra-titulo-formulario-texto {
        font-size: 18px;
    }

    .botoes-formulario-texto {
        text-transform: capitalize
    }
</style>