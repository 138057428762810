<template>
    <v-card class="mx-auto my-2" elevation="12" style="max-width: 700px; background-color: transparent;">
        <v-card-title 
            class="text-h6" 
            v-bind:style="{backgroundColor: dadosEstabelecimento.cor_background_barra_formularios, color: dadosEstabelecimento.cor_texto_barra_formularios, padding: '10px'}">
            <v-icon v-bind:style="{marginRight: '5px', color: dadosEstabelecimento.cor_texto_barra_formularios}">mdi-calendar-clock</v-icon>
            Data/Hora
        </v-card-title>

        <div class="corpo overflow-y-auto scroll-hidden">
            <v-container class="container container-pagina">
                <v-row justify="center">
                    <v-col cols="12">
                        <v-card v-bind:style="{backgroundColor:dadosEstabelecimento.cor_background_card_formularios}" dark v-if="buscandoHorarios">
                            <v-container class="grey lighten-5">
                                <v-row class="mb-6" justify="center" no-gutters>
                                    <v-card-title class="text-h6" v-text="'Buscando os seus horarios!'" v-bind:style="{color:dadosEstabelecimento.cor_texto_botoes}"/>
                                </v-row>
                                <v-row class="mb-6" justify="center" no-gutters>
                                    <v-progress-circular :size="80" :width="8" :color="dadosEstabelecimento.cor_principal" indeterminate />
                                </v-row>
                            </v-container>
                        </v-card>

                        <v-card v-bind:style="{backgroundColor:dadosEstabelecimento.cor_background_card_formularios}" dark v-else>
                            <v-card-title
                                class="text-h6"
                                v-text="'Selecione uma data e horário!'"
                                v-bind:style="{color: dadosEstabelecimento.cor_texto_formularios}"
                            />

                            <v-divider class="mx-4" v-bind:style="{backgroundColor:dadosEstabelecimento.cor_barra_divisoria}"/>

                            <v-container class="container-pagina">
                                <v-row justify="center" class="my-0" v-bind:style="{color: dadosEstabelecimento.cor_texto_formularios}">
                                    <v-col cols="6">
                                        <v-dialog
                                            ref="dialog"
                                            v-model="menu"
                                            :close-on-content-click="false"
                                            width="290px"
                                            transition="scale-transition"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    label="Calendário"
                                                    v-model="dateAgendamentoFormatada"
                                                    prepend-inner-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    @blur="dataAgendamento = parseDate(dateAgendamentoFormatada)"
                                                    v-on="on"
                                                    solo
                                                />
                                            </template>

                                            <v-date-picker
                                                v-model="dataAgendamento"
                                                no-title
                                                scrollable
                                                elevation="15"
                                                locale="pt-br"
                                                @input="menu = false"
                                                :min="dataMin"
                                                :max="dataMax"
                                            >
                                                <v-spacer />
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="$refs.dialog.save(date)"
                                                >   
                                                    FECHAR
                                                </v-btn>
                                            </v-date-picker>
                                        </v-dialog>
                                    </v-col>
                                </v-row>
                            </v-container>

                            <v-container class="container container-pagina">
                                <v-row justify="center">
                                    <v-col cols="12" class="divBlocoBotoesHorarios">
                                        <div v-for="horario in horarios" :key="horario.hora">
                                            <v-btn
                                                v-bind:style="{backgroundColor: dadosEstabelecimento.cor_botoes, color: dadosEstabelecimento.cor_texto_botoes, marginTop: '5px', marginRight: '5px'}"
                                                small
                                                tile
                                                @click="avancaParaTelaResumo(horario)"
                                            >
                                                <v-icon left>mdi-clock</v-icon>{{horario.hora}}
                                            </v-btn>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>                                            
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </v-card>
</template>


<script>
    import { mapActions, mapGetters } from "vuex";
    import api from "@/api";
    import moment from 'moment';

    export default {
        props: ['path'],
        data: vm => ({
            menu: false,
            dataAgendamento: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            dateAgendamentoFormatada: vm.formataData((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
            horarios: [],
            agenda: [],
            dataAgrupaHorarios: "",
            dataMin: "",
            dataMax: "",
            buscandoHorarios: true
        }),
        watch: {
            dataAgendamento () {
                this.dateAgendamentoFormatada = this.formataData(this.dataAgendamento)
                this.horarios = [];
                this.dataAgrupaHorarios = this.dataAgendamento;
                
                for(let indice=0; indice < this.agenda.length; indice++){
                    // Coleta somente os horários referentes a data de agrupamento.
                    if( this.dataAgrupaHorarios == this.retornaDataUs(this.agenda[indice].dthr_agendamento) ){
                        if(!this.dataPassado(this.agenda[indice].dthr_agendamento)) {
                            let horaMinuto = this.retornaHorario(this.agenda[indice].dthr_agendamento);
                            let ap_idagendaprofissional = this.agenda[indice].ap_idagendaprofissional;
                            if(!this.verificaHorarios(horaMinuto)){
                                this.horarios.push({'data': this.dataAgrupaHorarios, 'idagendaprofissional': ap_idagendaprofissional,'hora':horaMinuto, 'idagendamento':this.agenda[indice].idagendamento});
                            }
                        }
                    }
                }
            }
        },
        async mounted () {
            this.testaF5();
            this.bloqueiaBotaoVoltar();
            this.ativaComponentes();
        },
        computed: {
            ...mapGetters({
                getProfissional: "getProfissional",
                getServico: "getServico",
                getContrato: "getContrato",
                dadosEstabelecimento: "getDadosEstabelecimento"
            })
        },
        methods: {
            ...mapActions({
                goToRoute: "goToRoute",
                testaF5: "testaF5",
                bloqueiaBotaoVoltar: "bloqueiaBotaoVoltar",
                desativaBotoesNavegacao: "desativaBotoesNavegacao",
                salvarAgendamentoSelecionado: "salvarAgendamentoSelecionado"
            }),
            dataPassado(target) {
                var isafter = moment().isAfter(target);
                return isafter;
            },
            ativaComponentes () {
                this.$store.state.controleApp.botaoHome = true;
                this.$store.state.controleApp.botaoRetornoInicio = true;
                this.$store.state.controleApp.barraNavegacao = true;
                this.$store.state.controleApp.lblAtivoDataAgendamento = true;
                this.$store.state.controleApp.botaoResumo = false;

                if(this.$store.state.controleApp.botaoDataAgendamento == false) {
                    this.$store.state.controleApp.botaoDataAgendamento = true;
                }

                let idservico = this.getServico.idservico;
                let idprofissional = this.getProfissional.idprofissional;
                let idlocal = this.getContrato.idlocal;

                if( (idservico != "") && (idprofissional != "") ) {
                    // Consulta os horários referente ao local, serviço e profissional.
                    api.buscaAgendamentos(this.path, idservico, idlocal, idprofissional)
                        .then((response) => {
                            this.agenda = response.data;
                            this.dataAgrupaHorarios = "";
                            for(let indice = 0; indice < this.agenda.length; indice++){
                                if(indice == 0){
                                    // Coleta a data para agrupar horários.
                                    this.dataAgrupaHorarios = this.retornaDataUs(this.agenda[indice].dthr_agendamento);
                                    
                                    // Coleta a data que é exibida no input do calendário.
                                    this.dateAgendamentoFormatada = this.retornaDataPtBr(this.agenda[indice].dthr_agendamento);
                                    
                                    // Coleta a data inicial do calendário.
                                    this.dataMin = this.retornaDataUs(this.agenda[indice].dthr_agendamento);
                                }    

                                // Coleta a data final do calendário.
                                if( indice == (this.agenda.length - 1) ){
                                    this.dataMax = this.retornaDataUs(this.agenda[indice].dthr_agendamento);
                                }    

                                // Coleta somente os horários referentes a data de agrupamento.
                                if( this.dataAgrupaHorarios == this.retornaDataUs(this.agenda[indice].dthr_agendamento) ){
                                    if(!this.dataPassado(this.agenda[indice].dthr_agendamento)) {
                                        let horaMinuto = this.retornaHorario(this.agenda[indice].dthr_agendamento);
                                        let ap_idagendaprofissional = this.agenda[indice].ap_idagendaprofissional;
                                        if(!this.verificaHorarios(horaMinuto)){
                                            this.horarios.push({'data': this.dataAgrupaHorarios, 'idagendaprofissional': ap_idagendaprofissional,'hora':horaMinuto, 'idagendamento':this.agenda[indice].idagendamento});
                                        }
                                    }
                                }
                                this.buscandoHorarios = false;
                            }
                        }
                    );
                }
            },
            formataData (date) {
                if (!date) return null
                const [year, month, day] = date.split('-');
                return `${day}/${month}/${year}`
            },
            parseDate (date) {
                if (!date) return null
                const [day, month, year] = date.split('/')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },
            avancaParaTelaResumo (pHorario) {
                this.salvarAgendamentoSelecionado(pHorario);
                this.desativaBotoesNavegacao();
                this.goToRoute(`resumo`);
            },
            retornaHorario (pDataHora) {
                // Retorna somente o horário no formato hora:minuto.
                let arrayDataHora = pDataHora.split(" ");
                let horario = arrayDataHora[1];
                let arrayHoraMinutoSegundo = horario.split(":");
                return arrayHoraMinutoSegundo[0] + ":" + arrayHoraMinutoSegundo[1];
            },
            retornaDataPtBr (pDataHora) {
                // Retorna somente a data no formato dia/mês/ano.
                let arrayDataHora = pDataHora.split(" ");
                let data = arrayDataHora[0];
                let arrayAnoMesDia = data.split("-");
                return arrayAnoMesDia[2] + "/" + arrayAnoMesDia[1] + "/" + arrayAnoMesDia[0];
            },
            retornaDataUs (pDataHora) {
                // Retorna somente a data no formato ano-mês-dia.
                let arrayDataHora = pDataHora.split(" ");
                return arrayDataHora[0];
            },
            verificaHorarios(pHora) {
                for(let indice=0; indice < this.horarios.length; indice++){
                    let horario = this.horarios[indice];
                    if(pHora == horario.hora){
                        return true;
                    }
                }
                return false
            }
        }
    };
</script>

<style scoped>
    .corpo {
        height: 100vh;
        max-height: 580px
    }

    .container-pagina {
        height: 100%;
        max-width: 700px;
        margin: 0 auto;
    }

    .scroll-hidden::-webkit-scrollbar {
        display: none;
    }

    .divBlocoBotoesHorarios {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 auto;
        margin-bottom: 5px;
        padding-bottom: 17px;
    }
</style>