import Vue from 'vue'
import Vuex from 'vuex'
import Router from '@/routes'
import api from '@/api';

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        alert: { 
            show: false,
            text: "",
            timeout: 2000
        },
        controleApp: {
            barraTopo: true,
            botaoHome: false,
            botaoRetornoInicio: false,
            botaoSinoAlarmes: false,
            barraNavegacao: false,
            botaoServicos: false,
            botaoProfissionais: false,
            botaoDataAgendamento: false,
            botaoResumo: false,
            lblAtivoServicos: false,
            lblAtivoProfissionais: false,
            lblAtivoDataAgendamento: false,
            lblAtivoResumo: false,
        },
        usuarioLogado: false,
        primeiraPagina: true,
        urlPathCliente: "",
        contrato: {},
        telaAtual: '/home',
        origemMeusHorarios: 'home',
        origemLogin: 'home',
        servicoSelecionado: {},
        profissionalSelecionado: {},
        agendamentoSelecionado: {},
        agendamentoPendente: false,
        usuarioToken: "",
        telaRetornoPosLogin: "",
        ultimosEstabelecimentos: [],
        planoFundoApp: "",
        visualizaLogoAgendarme: true,
        visualizaTopoPaginaInicio: false
    },
    mutations: {
        showAlert(state, value) {
            state.alert.text = value;
            state.alert.show = true;
        },
        hideAlert(state) {
            state.alert.text = "";
            state.alert.show = false;
        },
        setAgendamentoPendente(state, value){
            state.agendamentoPendente = value;
        },
        desativaBotoesNavegacao (state) {
            state.controleApp.lblAtivoServicos        = false;
            state.controleApp.lblAtivoProfissionais   = false;
            state.controleApp.lblAtivoDataAgendamento = false;
            state.controleApp.lblAtivoResumo          = false;
        },
        updateLogin(state, value){
            // Coleta o token da validação do usuário no sistema.
            state.usuarioToken   = value.access_token;
            state.usuarioLogado  = true;
            state.controleApp.botaoMenu = true;
        },
        clearLogin(state) {
            state.usuarioToken   = null;
            state.usuarioLogado  = false;
            state.controleApp.botaoMenu = true;
            localStorage.clear('loginToken');
        },
        updateTelaAtual(state, value) {
            state.telaAtual = value;
        },
        setContrato(state, pContrato) {
            state.contrato = pContrato;
            
            if(state.ultimosEstabelecimentos) {
                let x = state.ultimosEstabelecimentos.filter( e => (e.url_path == pContrato.url_path) );
                if(!x.length){
                    state.ultimosEstabelecimentos.push(pContrato);
                }
            } else {
                state.ultimosEstabelecimentos = [];
                state.ultimosEstabelecimentos.push(pContrato);
            }
            
            localStorage.setItem("ultimosEstabelecimentos", JSON.stringify(state.ultimosEstabelecimentos));
        },
        setPathCliente(state, value) {
            state.urlPathCliente = value;
        },
        servicoSelecionado(state, value) {
            state.servicoSelecionado = value;
        },
        profissionalSelecionado(state, value) {
            state.profissionalSelecionado = value;
        },
        agendamentoSelecionado(state, value) {
            state.agendamentoSelecionado = value;
        }
    },
    getters: {
        syncAlert(state) {
            return state.alert;
        },
        getControleApp(state){
            return state.controleApp;
        },
        getAgendamentoPendente(state) {
            return state.agendamentoPendente;
        },
        getDadosEstabelecimento(state) {
            let estabelecimento = {
                idcontrato: "",
                idlocal: "",
                nome: "",
                enderecoCompleto: "",
                endereco: "",
                complemento: "",
                bairro: "",
                municipio: "",
                gps: "",
                informacoes_cliente: "",
                url_path: null,
                url_logo: null,
                url_background: null,
                tema: "",
                cor_principal: "#424242",
                cor_secundaria: '#424242',
                cor_menu: "#8b8b8b",
                cor_botoes: "#efb300",
                cor_texto_botoes: "#424242",
                cor_background_botoes_tela_inicial: "#efb300",
                cor_texto_botoes_tela_inicial: "#ffffff",
                cor_borda_botoes_tela_inicial: "#efb300",
                cor_icone_botoes_tela_inicial: "#ffffff",
                cor_barra_divisoria: "#efb300",
                cor_barra_titulos_paginas: "#8b8b8b",
                cor_botoes_navegacao_paginas: "#efb300",
                cor_borda_botoes_navegacao_paginas: "#efb300",
                cor_fundo_barra_navegacao: "#efb300",
                cor_texto_barra_endereco: "#ffffff",
                cor_botao_menu: "#efb300",
                cor_botao_home: "#efb300",
                cor_botao_alerta: "#efb300",
                cor_botoes_barra_navegacao: "#efb300",
                cor_texto_botoes_barra_navegacao: "#000000important",
                cor_texto_barra_topo_dialog_informacao: "#ffffff",
                cor_background_barra_formularios: "#424242",
                cor_texto_barra_formularios: "#ffffff",
                cor_texto_formularios: "#424242",
                cor_background_campo_pesquisa_servicos: "#ebebeb",
                cor_background_card_formularios: "#ebebeb",
                cor_texto_login: "#efb300",
                cor_texto_esqueci_minha_senha: "#efb300",
                cor_background_botoes_cancelar: "#6c757d",
                cor_texto_botoes_cancelar: "#424242"
            }
            
            if(state.contrato) {
                if(estabelecimento.endereco && estabelecimento.endereco.length > 2){
                    estabelecimento.enderecoCompleto = `${estabelecimento.endereco}, ${estabelecimento.complemento}, ${estabelecimento.bairro}, ${estabelecimento.municipio}.`
                } else {
                    estabelecimento.enderecoCompleto = "agendarme.com | Sistema de agendamento online";
                }

                estabelecimento.endereco              = state.contrato.endereco;
                estabelecimento.complemento           = state.contrato.complemento;
                estabelecimento.bairro                = state.contrato.bairro;
                estabelecimento.municipio             = state.contrato.municipio;
                estabelecimento.gps                   = state.contrato.gps;
                estabelecimento.informacoes_cliente   = state.contrato.informacoes_cliente; 
                estabelecimento.url_path              = state.contrato.url_path;
                estabelecimento.url_logo              = state.contrato.url_logo;
                estabelecimento.url_background        = state.contrato.url_background;

                if( state.contrato.cor_principal != null ){
                    estabelecimento.cor_principal = state.contrato.cor_principal;
                }
                
                if( state.contrato.cor_secundaria != null ){
                    estabelecimento.cor_secundaria = state.contrato.cor_secundaria;
                }

                estabelecimento.cor_menu = !state.contrato.cor_menu ? "#efb300" : state.contrato.cor_menu;
                estabelecimento.cor_botoes = !state.contrato.cor_botoes ? "#efb300" : state.contrato.cor_botoes;
                estabelecimento.cor_texto_botoes = !state.contrato.cor_texto_botoes ? "#424242" : state.contrato.cor_texto_botoes;
                estabelecimento.cor_borda_botoes_tela_inicial = !state.contrato.cor_borda_botoes_tela_inicial  ? "#c6c096" : state.contrato.cor_borda_botoes_tela_inicial;
                estabelecimento.cor_icone_botoes_tela_inicial = !state.contrato.cor_icone_botoes_tela_inicial  ? "#40a22d" : state.contrato.cor_icone_botoes_tela_inicial;
                estabelecimento.cor_barra_divisoria = !state.contrato.cor_barra_divisoria ? "#efb300" : state.contrato.cor_barra_divisoria
                estabelecimento.cor_barra_titulos_paginas = !state.contrato.cor_barra_titulos_paginas ? "#efb300" : state.contrato.cor_barra_titulos_paginas
                estabelecimento.cor_botoes_navegacao_paginas = !state.contrato.cor_botoes_navegacao_paginas ? "#efb300" : state.contrato.cor_botoes_navegacao_paginas
                estabelecimento.cor_borda_botoes_navegacao_paginas = !state.contrato.cor_borda_botoes_navegacao_paginas ? "#efb300" : state.contrato.cor_borda_botoes_navegacao_paginas;
                estabelecimento.cor_fundo_barra_navegacao = !state.contrato.cor_fundo_barra_navegacao ? "#efb300" : state.contrato.cor_fundo_barra_navegacao;
                estabelecimento.cor_texto_barra_endereco = !state.contrato.cor_texto_barra_endereco ? "#ffffff" : state.contrato.cor_texto_barra_endereco;
                estabelecimento.cor_botao_alerta = !state.contrato.cor_botao_alerta ? "#efb300" : state.contrato.cor_botao_alerta;
                estabelecimento.cor_botoes_barra_navegacao = !state.contrato.cor_botoes_barra_navegacao ? "#efb300" : state.contrato.cor_botoes_barra_navegacao;
                estabelecimento.cor_texto_botoes_barra_navegacao = !state.contrato.cor_texto_botoes_barra_navegacao ? "black" : state.contrato.cor_texto_botoes_barra_navegacao;
                estabelecimento.cor_texto_barra_topo_dialog_informacao = !state.contrato.cor_texto_barra_topo_dialog_informacao ? "white" : state.contrato.cor_texto_barra_topo_dialog_informacao;
                estabelecimento.cor_background_barra_formularios = !state.contrato.cor_background_barra_formularios ? "#424242" : state.contrato.cor_background_barra_formularios;
                estabelecimento.cor_texto_barra_formularios = !state.contrato.cor_texto_barra_formularios ? "white" : state.contrato.cor_texto_barra_formularios;
                estabelecimento.cor_texto_formularios = !state.contrato.cor_texto_formularios ? "#424242" : state.contrato.cor_texto_formularios;
                estabelecimento.cor_background_campo_pesquisa_servicos = !state.contrato.cor_background_campo_pesquisa_servicos ? "#ebebeb" : state.contrato.cor_background_campo_pesquisa_servicos;
                estabelecimento.cor_background_card_formularios = !state.contrato.cor_background_card_formularios ? "#ebebeb" : state.contrato.cor_background_card_formularios;
                estabelecimento.cor_texto_login = !state.contrato.cor_texto_login ? "#efb300" : state.contrato.cor_texto_login;
                estabelecimento.cor_texto_esqueci_minha_senha = !state.contrato.cor_texto_esqueci_minha_senha ? "#efb300" : state.contrato.cor_texto_esqueci_minha_senha;
                estabelecimento.cor_background_botoes_cancelar = !state.contrato.cor_background_botoes_cancelar ? "#6c757d" : state.contrato.cor_background_botoes_cancelar;
                estabelecimento.cor_texto_botoes_cancelar = !state.contrato.cor_texto_botoes_cancelar ? "#424242" : state.contrato.cor_texto_botoes_cancelar;
            }

            return estabelecimento;
        },
        getContrato(state) {
            return state.contrato;
        },
        getServico(state) {
            return state.servicoSelecionado[0];
        },
        getProfissional(state) {
            return state.profissionalSelecionado;
        },
        getAgendamento(state) {
            return state.agendamentoSelecionado;
        },
        getAuthorization(state){
            return 'Bearer ' + state.usuarioToken;
        },
        getTelaAtual(state) {
            return state.telaAtual;
        },
        verificaLogado(state) {
            return state.usuarioLogado;
        },
        getUltimosEstabelecimentos(state) {
            return state.ultimosEstabelecimentos;
        },
        getCarregando(state) {
            // Quando terminar de carregar a pagina a primeira vez,
            //   essa flag se torna false
            return state.primeiraPagina;
        }
    },
    actions: {
        showAlert({commit}, value) {
            commit("showAlert", value);
            setTimeout(()=>{commit("hideAlert")}, 7000)
        },
        verificaAgendamentoPendente({getters, dispatch, commit}) {
            if( getters.getAgendamentoPendente ) {
                let dadosAgendamento = {
                    idagendamento:      getters.getAgendamento.idagendamento,
                    hora:               getters.getAgendamento.hora,
                    data:               getters.getAgendamento.data,
                    profissional:       getters.getProfissional.idprofissional,
                    servico:            getters.getServico.idservico,
                    path:               getters.getContrato.url_path,
                    local:              getters.getContrato.idlocal,
                    agendaProfissional: getters.getAgendamento.idagendaprofissional
                };


                api.agendar(dadosAgendamento)
                .then(() => {
                    dispatch("showAlert", 'Agendamento confirmado!');
                    commit("setAgendamentoPendente",false);
                    dispatch("goToRoute", "/minha-agenda");
                })
                .catch( (r) => {
                    console.table(r);
                    dispatch("showAlert", "Problema ao agendar");
                })
            }
        },
        configurarContrato({state, dispatch, commit}) {
            let path = state.urlPathCliente;
            if(path && path !== 'undefined') {
                api.buscaContrato( path )
                .then( (response ) => {
                    let dadosContrato = response.data;
                    let contrato = {
                        idcontrato: dadosContrato.idcontrato, 
                        idlocal: dadosContrato.local.idlocal, 
                        nome: dadosContrato.nome_fantasia,
                        endereco: dadosContrato.local.endereco,
                        complemento: dadosContrato.local.complemento,
                        bairro: dadosContrato.local.bairro,
                        municipio: dadosContrato.local.municipio,
                        gps: dadosContrato.local.gps,
                        informacoes_cliente: dadosContrato.informacoes_cliente,
                        url_path: dadosContrato.url_path,
                        url_logo: dadosContrato.url_logo,
                        url_background: dadosContrato.url_background,
                        tema: dadosContrato.tema,
                        cor_principal: dadosContrato.cor_principal,
                        cor_secundaria: dadosContrato.cor_secundaria,
                        cor_menu: dadosContrato.cor_menu,
                        cor_botoes: dadosContrato.cor_botoes,
                        cor_texto_botoes: dadosContrato.cor_texto_botoes,
                        cor_borda_botoes_tela_inicial: dadosContrato.cor_borda_botoes_tela_inicial,
                        cor_icone_botoes_tela_inicial: dadosContrato.cor_icone_botoes_tela_inicial,
                        cor_barra_divisoria: dadosContrato.cor_barra_divisoria,
                        cor_barra_titulos_paginas: dadosContrato.cor_barra_titulos_paginas,
                        cor_botoes_navegacao_paginas: dadosContrato.cor_botoes_navegacao_paginas,
                        cor_borda_botoes_navegacao_paginas: dadosContrato.cor_borda_botoes_navegacao_paginas,
                        cor_fundo_barra_navegacao: dadosContrato.cor_fundo_barra_navegacao
                    };

                    localStorage.setItem("contrato", JSON.stringify(contrato));
                    localStorage.setItem("path", path);

                    commit("setContrato", contrato);
                })
                .catch( error =>{
                    dispatch(
                        "showAlert", 
                        [
                            'Humm...', 
                            "Infelizmente a pesquisa não retornou nada!", 
                            "Verifique se você digitou o caminho corretamente",
                            "",
                            `(${error.response.data.message.Erro[0]})`
                        ]
                    );
                })
            } else {
                console.log("store - configurarContrato - Configurando o ambiente...");
            }
        },
        testaF5({dispatch, state}){
            setTimeout( () => {
                if(state.primeiraPagina){
                    state.primeiraPagina = false;
                    dispatch("setAgendamentoPendente", false);
                    dispatch("goToRoute", "/home");
                }
            }, 1000);
        },
        bloqueiaBotaoVoltar() {
            window.history.forward();
        },
        noBack() {
            window.history.forward();
        },
        testaF5SemNavegar({dispatch, state}){
            setTimeout( () => {
                if(state.primeiraPagina){
                    state.primeiraPagina = false;
                    dispatch("setAgendamentoPendente", false);
                }
            }, 1000);
        },
        goToRoute({commit, dispatch, state}, fullpath) {
            commit("updateTelaAtual", fullpath);

            if(fullpath === "inicio"){
                state.primeiraPagina = false;
                dispatch("goToRoute", `/${state.urlPathCliente}/inicio`);
            }

            // if(Router.currentRoute.meta.page !== path) {
                Router.push(fullpath).catch(error => {
                    if (error.name != "NavigationDuplicated") {
                      throw error;
                    }
                });
            // }
        },
        loginRealizado({commit}, value) {
            localStorage.setItem('loginToken', JSON.stringify(value));
            commit("updateLogin", value);
        },
        limpaLogin({commit}) {
            commit("clearLogin");
        },
        async carregarStore({commit, state}) {
            let path = localStorage.getItem('path');
            
            commit("setPathCliente", path);
            
            let token = localStorage.getItem('loginToken');
            if(token) {
                commit("updateLogin", JSON.parse(token));
            }

            state.ultimosEstabelecimentos = JSON.parse(localStorage.getItem("ultimosEstabelecimentos"));
            setTimeout( () => {state.primeiraPagina = false}, 1500);
        },
        setAgendamentoPendente({commit}, value){
            commit("setAgendamentoPendente", value);
        },
        desativaBotoesNavegacao({commit}){
            commit("desativaBotoesNavegacao");
        },
        salvarServicoSelecionado({commit}, value){
            commit("servicoSelecionado", value);
        },
        salvarProfissionalSelecionado({commit}, value){
            commit("profissionalSelecionado", value);
        },
        salvarAgendamentoSelecionado({commit}, value){
            commit("agendamentoSelecionado", value);
        },
        setPathCliente({commit}, value) {
            commit("setPathCliente", value);
        },
    }
})