<template>
    <v-card
        class="mx-auto my-2"
        elevation="12"
        style="max-width: 700px; 
        background-color: transparent;"
    >
        <v-card-title class="text-h6" v-bind:style="{backgroundColor: dadosEstabelecimento.cor_background_barra_formularios, color: dadosEstabelecimento.cor_texto_barra_formularios, padding: '10px'}">
            <v-icon v-bind:style="{marginRight: '5px', color: dadosEstabelecimento.cor_texto_barra_formularios}">mdi-account-outline</v-icon>
            Profissional
        </v-card-title>


        <div class="corpo overflow-y-auto scroll-hidden">
            <v-container class="container container-pagina">
                <v-row v-for="profissional in profissionais" :key="profissional.profissional">
                    <v-col class="cols='12'">
                        <v-card v-bind:style={backgroundColor:dadosEstabelecimento.cor_background_card_formularios}>
                            <div>
                                <v-list-item three-line>
                                    <v-list-item-content>
                                        <v-list-item-avatar tile size="150" >
                                            <v-img contain :src="profissional.admin.url_imagem"/>
                                        </v-list-item-avatar>

                                        <v-card-title
                                            class="text-h6"
                                            v-text="profissional.admin.nome"
                                            v-bind:style="{color: dadosEstabelecimento.cor_texto_formularios}"
                                        />

                                        <v-divider class="mx-4" v-bind:style="{backgroundColor:dadosEstabelecimento.cor_barra_divisoria}"/>

                                        <v-card-subtitle 
                                            v-text="profissional.biografia"
                                            v-bind:style="{color: dadosEstabelecimento.cor_texto_formularios}"
                                        />
                                    </v-list-item-content>
                                </v-list-item>

                                <v-col cols="12" xl="4" md="12" class="py-0 px-6">
                                    <v-card-actions>
                                        <v-btn 
                                            class="mt-5" 
                                            block 
                                            v-bind:style="{backgroundColor: dadosEstabelecimento.cor_botoes}" 
                                            @click="avancaParaTelaDataAgendamento(profissional)"
                                        >
                                            <span v-bind:style="{color: dadosEstabelecimento.cor_texto_botoes, textTransform: 'capitalize'}">
                                                Selecionar
                                            </span>
                                        </v-btn>
                                    </v-card-actions>
                                </v-col>
                            </div>
                        </v-card>                                            
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </v-card>
</template>


<script>
    import { mapGetters, mapActions } from "vuex";
    import api from "@/api";


    export default {
        props: ['path'],

        data: () => ({
            profissionais: []
        }),
        async mounted () {
            this.ativaComponentes();
            this.testaF5();
            this.bloqueiaBotaoVoltar();
            this.getProfissionais();
        },
        computed: {
            ...mapGetters({
                getContrato: "getContrato",
                getServico: "getServico",
                dadosEstabelecimento: "getDadosEstabelecimento"
            })
        },
        methods: {
            ...mapActions({
                salvarProfissionalSelecionado: "salvarProfissionalSelecionado",
                desativaBotoesNavegacao: "desativaBotoesNavegacao",
                goToRoute: "goToRoute",
                testaF5: "testaF5",
                bloqueiaBotaoVoltar: "bloqueiaBotaoVoltar"
            }),
            getProfissionais () {
                let idlocal = this.getContrato.idlocal;
                let idservico = this.getServico.idservico;
                    
                if((this.path != "") && (idservico != "")){
                    // Consulta os profissionais referente ao serviço e local.
                    api.buscaProfissionais(this.path, idservico, idlocal )
                    .then((response) => {
                        this.profissionais = response.data;
                    });
                }
            },
            ativaComponentes () {
                this.$store.state.controleApp.botaoHome = true;
                this.$store.state.controleApp.botaoRetornoInicio = true;
                this.$store.state.controleApp.barraNavegacao = true;
                this.$store.state.controleApp.lblAtivoProfissionais = true;
                this.$store.state.controleApp.botaoProfissionais = true;
                this.$store.state.controleApp.botaoDataAgendamento = false;
                this.$store.state.controleApp.botaoResumo = false;
            },
            avancaParaTelaDataAgendamento (pProfissional) {
                this.salvarProfissionalSelecionado(pProfissional);
                this.desativaBotoesNavegacao();
                this.goToRoute(`data-agendamento`);
            }
        }
    };
</script>

<style scoped>
    .corpo {
        height: 100vh;
        max-height: 580px
    }

    .container-pagina {
        height: 100%;
        max-width: 700px;
        margin: 0 auto;
    }

    .scroll-hidden::-webkit-scrollbar {
        display: none;
    }
</style>