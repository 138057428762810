<template>
  <v-card
    class="mx-auto my-2"
    elevation="12"
    style="max-width: 700px; background-color: transparent"
  >
    <div class="corpo">
      <v-container class="container container-pagina">
        <v-card
          max-width="400"
          class="mx-auto d-flex align-start mb-6"
          style="background: #cfcfcf00; height: 100%"
        >
          <v-container>
            <!-- Linha do card Meus horários -->
            <v-row dense>
              <v-col cols="12" class="ma-0 pa-0">
                <v-card style="background-color:rgba(0, 0, 0, 0.5); color: #f1edd3;">
                  <v-row class="pa-0 ma-0">
                    <v-col cols="12" class="pa-0 ma-0">
                      <v-card-title
                        class="text-h5 ma-0 px-4 py-2"
                        v-text="'Meus horários'"
                      />
                    </v-col>
                    <v-col cols="12" class="pa-0 ma-0">
                      <div class="d-flex flex-no-wrap justify-space-between">
                        <div>
                          <v-card-actions>
                            <v-btn
                              class="mt-5"
                              outlined
                              rounded
                              small
                              @click="avancaParaTelaMinhaAgenda"
                              color="#EFB300"
                            >
                              Acessar
                            </v-btn>
                          </v-card-actions>
                        </div>
                        
                        <v-avatar class="mx-10 my-2">
                          <v-img
                            style="border-radius: 12px"
                            src="/assets/meusHorarios.png"
                          />
                        </v-avatar>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>

            <!-- Linha dos card dos clientes -->
            <v-row dense>
              <v-col
                v-for="(item, i) in getUltimosEstabelecimentos"
                :key="i"
                cols="12"
                class="ma-0 my-2 pa-0"
              >
                <v-card style="background-color:rgba(0, 0, 0, 0.5); color: #f1edd3;">
                  <v-row class="pa-0 ma-0">
                    <v-col cols="12" class="pa-0 ma-0">
                      <v-card-title
                        class="text-h5 ma-0 px-4 py-2"
                        v-text="item.nome"
                      />
                    </v-col>

                    <v-col cols="12" class="pa-0 ma-0">
                      <div class="d-flex flex-no-wrap justify-space-between">
                        <div>
                          <v-card-actions>
                            <v-btn
                              class="mt-5"
                              outlined
                              rounded
                              small
                              @click="acessar(item.url_path)"
                              color="#EFB300"
                            >
                              Acessar
                            </v-btn>
                          </v-card-actions>
                        </div>
                        <v-avatar class="mx-4 my-2" size="100" tile>
                          <v-img
                            style="border-radius: 12px"
                            :src="item.url_logo"
                          />
                        </v-avatar>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-container>
    </div>
  </v-card>
</template>


<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["path"],
  mounted() {
    this.desativaComponentes();
    this.verificaLogin();
    this.testaF5();
    this.bloqueiaBotaoVoltar();
    this.setaPlanoDeFundo();
    this.setaOrigemMeusHorarios();
    this.setaOrigemLogin();
  },
  computed: {
    ...mapGetters({
      dadosEstabelecimento: "getDadosEstabelecimento",
      getUltimosEstabelecimentos: "getUltimosEstabelecimentos",
    }),
  },
  methods: {
    ...mapActions({
      setPathCliente: "setPathCliente",
      goToRoute: "goToRoute",
      testaF5: "testaF5",
      bloqueiaBotaoVoltar: "bloqueiaBotaoVoltar",
      desativaBotoesNavegacao: "desativaBotoesNavegacao",
    }),
    setaOrigemMeusHorarios() {
      // Variável utilizada no controle de visualização do botão retornar na PaginaMinhaAgenda
      this.$store.state.origemMeusHorarios = "home";
    },
    setaOrigemLogin() {
      // Variável utilizada no controle de visualização do botão retornar na PaginaLogin
      this.$store.state.origemLogin = "home";
    },
    setaPlanoDeFundo() {
      if(window.location.pathname == '/home') {
        this.$store.state.visualizaLogoAgendarme = true;
        this.$store.state.visualizaTopoPaginaInicio = false;
        this.$store.state.planoFundoApp = {backgroundImage: `url("/assets/fundoCouroTesouras.png")`};
      } else {
        this.$store.state.visualizaLogoAgendarme = false;
        this.$store.state.visualizaTopoPaginaInicio = true;
        this.$store.state.planoFundoApp = {backgroundImage: `url("${this.dadosEstabelecimento.url_background}")`};
      }
    },
    desativaComponentes() {
      this.$store.state.controleApp.botaoHome = false;
      this.$store.state.controleApp.botaoRetornoInicio = false;
      this.$store.state.controleApp.barraNavegacao = false;
      this.$store.state.controleApp.botaoServicos = false;
      this.$store.state.controleApp.botaoProfissionais = false;
      this.$store.state.controleApp.botaoDataAgendamento = false;
      this.$store.state.controleApp.botaoResumo = false;
    },
    verificaLogin() {
      // Ativa ou desativa o botão do menu conforme o status do usuário logado.
      this.$store.state.controleApp.botaoMenu = this.$store.state.usuarioLogado;
      this.$store.state.telaRetornoPosLogin = "";
    },
    acessar(destino) {
      this.setPathCliente(destino);
      this.goToRoute(destino);
    },
    avancaParaTelaMinhaAgenda() {
      if (this.$store.state.usuarioLogado) {
        this.desativaBotoesNavegacao();
        this.goToRoute("minha-agenda");
      } else {
        this.$store.state.telaRetornoPosLogin = "minha-agenda";
        this.goToRoute(`login`);
      }
    },
  }
};
</script>

<style scoped>
.corpo {
  height: 100vh;
  max-height: 580px;
}

.container-pagina {
  height: 100%;
  max-width: 700px;
  margin: 0 auto;
}
</style>