import config from '@/config/config.js';
import serverrequest from '@/serverrequest';

import store from '@/store.js'


function login(dados){
    return serverrequest.postCall(`${config.apiUrl}auth/login`, dados)
}

function refresh(){
    return serverrequest.postCall(`${config.apiUrl}auth/refresh`, null, true)
}

function buscaServicos(path) {
    return serverrequest.getCall(`${config.apiUrl}${path}/servicos/listar`);
}

function buscaProfissionais(path, idservico, idlocal ) {
    return serverrequest.getCall(`${config.apiUrl}${path}/profissionais/listar/${idservico}/${idlocal}`);
}


function buscaAgendamentos(path, idservico, idlocal, idprofissional) {
    return serverrequest.getCall(`${config.apiUrl}${path}/horarios/listar/${idservico}/${idlocal}/${idprofissional}`);
}

function buscaInformacoes() {
    return serverrequest.postCall(`${config.apiUrl}auth/informacoes`, null, true);
}

function agendar(dadosAgendamentos) {
    return  serverrequest.postCall(`${config.apiUrl}auth/agendar`, dadosAgendamentos, true);
}

function logout() {
    return serverrequest.postCall(`${config.apiUrl}auth/logout`, null, true);
}

function setPassword(usuarios) {
    return serverrequest.postCall(`${config.apiUrl}auth/setpassword`, usuarios, false)
}

function listarAgenda() {
    // Consulta a agenda do usuário logado
    return serverrequest.getCall(`${config.apiUrl}auth/listaragenda`, true)
}

function deletarAgendamento(idagendamento) {
    let dados = {
        'headers': {
            'Accept': 'application/json',
            'Authorization': store.getters['getAuthorization']
        },
            'data': {
                'idagendamento': idagendamento
            }
        };


    return serverrequest.deleteCall(`${config.apiUrl}auth/excluirAgendamento`, dados)
}

function cadastro(usuarios) {
    return serverrequest.postCall(`${config.apiUrl}auth/cadastro`, usuarios, false)
}

function atualizar(dados) {
    return serverrequest.postCall(`${config.apiUrl}auth/atualizar`, dados, true);
}

function buscaContrato(path) {
    return serverrequest.getCall(`${config.apiUrl}${path}`)
}

function resetPassword(dados) {
    return serverrequest.postCall(`${config.apiUrl}auth/resetpassword`, dados, false)
}

export default {
    buscaServicos,
    buscaProfissionais,
    buscaAgendamentos,
    buscaInformacoes,
    agendar,
    refresh,
    logout,
    setPassword,
    listarAgenda,
    deletarAgendamento,
    cadastro,
    atualizar,
    buscaContrato,
    resetPassword,
    login 
};