import Vue from 'vue';
import store from '@/store'
import App from './App.vue';

import moment from 'moment'
import vuetify from './plugins/vuetify';

import router from '@/routes';

import NProgress from 'vue-nprogress';

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

Vue.filter('formataDataHora', function (value) {
    if(value){ return moment(String(value)).format('DD/MM/YYYY HH:mm'); }
    else { return value; }
})

Vue.config.productionTip = false

const options = {
    latencyThreshold: 200, // Number of ms before progressbar starts showing, default: 100,
    router: true, // Show progressbar when navigating routes, default: true
    http: true // Show progressbar when doing Vue.http, default: true
};

Vue.use(NProgress, options)

const nprogress = new NProgress()

new Vue({
    store,
    router,
    vuetify,
    nprogress,
    render: h => h(App),
    mounted: function () { }
}).$mount('#app')
