import Vue from 'vue';

import VueRouter from 'vue-router';

import PaginaInicio from './components/pages/PaginaInicio.vue';
import PaginaHome from './components/pages/PaginaHome.vue';
import PaginaServicos from './components/pages/PaginaServicos.vue';
import PaginaProfissionais from './components/pages/PaginaProfissionais.vue';
import PaginaDataAgendamento from './components/pages/PaginaDataAgendamento.vue';
import PaginaResumo from './components/pages/PaginaResumo.vue';
import PaginaLogin from './components/pages/PaginaLogin.vue';
import PaginaDadosPessoais from './components/pages/PaginaDadosPessoais.vue';
import PaginaCadastroConta from './components/pages/PaginaCadastroConta.vue';
import PaginaMinhaAgenda from './components/pages/PaginaMinhaAgenda.vue';
import PaginaRecuperarSenha from './components/pages/PaginaRecuperarSenha.vue';
import PaginaTermo from './components/pages/PaginaTermo.vue';


Vue.use(VueRouter);

const routes =
[
	{
		path: '/',
		redirect: '/home'
	},
	{
		path: '/home',
		component: PaginaHome,
		meta: { page: "/home" }
	},
	{
		path: '/login',
		component: PaginaLogin,
		props: true,
		meta: { page: "login" }
	},
	{
		path: '/dados-pessoais',
		component: PaginaDadosPessoais,
		meta: { page: "/dados-pessoais" }
	},
	{
		path: '/cadastro-conta',
		component: PaginaCadastroConta,
		meta: { page: "/cadastro-conta" }
	},
	{
		path: '/minha-agenda',
		component: PaginaMinhaAgenda,
		props: true,
		meta: { page: "minha-agenda" }
	},
	{
		path: '/recuperar-senha/:token',
		component: PaginaRecuperarSenha,
		props: true,
		meta: { page: "/recuperar-senha" }
	},
	{
		path: '/termo',
		component: PaginaTermo,
		meta: { page: "/termo" }
	},

	
	// -- ROTAS QUE DEPENDEM DO PATH
	{
		path: '/:path',
		redirect: '/:path/inicio'
	},
	{
		path: '/:path/inicio',
		component: PaginaInicio,
		props: true,
		meta: { page: "inicio" }
	},
	{
		path: '/:path/servicos',
		component: PaginaServicos,
		props: true,
		meta: { page: "servicos" }
	},
	{
		path: '/:path/profissionais',
		component: PaginaProfissionais,
		props: true,
		meta: { page: "profissionais" }
	},
	{
		path: '/:path/data-agendamento',
		component: PaginaDataAgendamento,
		props: true,
		meta: { page: "data-agendamento" }
	},
	{
		path: '/:path/resumo',
		component: PaginaResumo,
		props: true,
		meta: { page: "resumo" }
	},
];

const router = new VueRouter({
	mode: 'history',
	routes
});
  
router.beforeEach((to, from, next) => {
	next();
});
  
export default router;
  